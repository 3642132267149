import styled from 'styled-components';

import { media } from 'helpers/breakpoints';

import { LastSearchCard } from 'components/PageWrappers/SharedComponents/LastSearchCard/LastSearchCard';

const Header = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing.S8};
  margin-bottom: ${({ theme }) => theme.spacing.M16};
  border-bottom: 1px solid ${({ theme }) => theme.colors.GREY_LIGHT};

  ${media.large} {
    display: flex;
    justify-content: space-between;
    padding-bottom: ${({ theme }) => theme.spacing.M16};
  }
`;

const Heading = styled.h5`
  ${({ theme }) => theme.fontSize.L24};
`;

const StyledLastSearchCard = styled(LastSearchCard)<{ isDisabled: boolean }>`
  padding: ${({ theme }) => `${theme.spacing.S8} ${theme.spacing.S12}`};
  margin-bottom: ${({ theme }) => theme.spacing.M16};
  box-shadow: ${({ isDisabled, theme }) =>
    isDisabled ? 'none' : theme.shadow.CONTAINER};
  border-radius: ${({ theme }) => theme.borderRadius.default};
`;

export { Header, Heading, StyledLastSearchCard };
