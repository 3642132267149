import { StarOutlineIc } from '@dsch/dd-icons';
import { CDN_STATIC_ASSETS } from 'utils';

import { Label } from 'components/Toolkit/Inputs/Label';
import { TextField } from 'components/Toolkit/Inputs/TextField';
import { RadioButton } from 'components/Toolkit/Inputs/RadioButton';
import { Tag } from 'components/Toolkit/Tags/Tag';

import { NotificationFrequency } from 'components/SearchPage/hooks/useSavedSearch';

import type { SavedSearchModalProps } from 'components/PageWrappers/SharedComponents/SavedSearchModal/SavedSearchModal.typed';

import {
  StyledSavedSearchesModal,
  Heading,
  HeadingText,
  Saving,
  CloseButton,
  Prompt,
  TagList,
  TagListItem,
  SavedSearchButton,
} from 'components/PageWrappers/SharedComponents/SavedSearchModal/SavedSearchModal.styled';
import { Spacer } from 'helpers/genericStyles';

import { accessibleOnClick } from 'utils/Accessibility';
import { disableScroll, enableScroll } from 'helpers/scroll';

const SavedSearchModal = (props: SavedSearchModalProps) => {
  const {
    isOpen,
    askToClose,
    isInstantAlertAvailable,
    terms,
    onSave,
    saving,
    name,
    setName,
    selected = NotificationFrequency.DAILY,
    setSelected,
  } = props;

  const onClick = () => onSave(selected, name);

  /**
   * Not that transitions allow work when using the isOpen prop to display the modal
   * rather than conditionally rendering the modal based on the presence of this prop.
   * More on this may be found here: https://reactcommunity.org/react-modal/styles/transitions/
   */

  return (
    <StyledSavedSearchesModal
      isOpen={isOpen}
      onRequestClose={askToClose}
      closeTimeoutMS={200}
      onAfterOpen={disableScroll}
      onAfterClose={enableScroll}
      overlayClassName="bottom-sheet-overlay"
    >
      <Heading>
        <HeadingText>
          <StarOutlineIc width="24" height="24" />
          <span>
            <b>Save your search</b>
          </span>
        </HeadingText>
        <CloseButton
          width="16"
          height="16"
          {...accessibleOnClick(askToClose)}
        />
      </Heading>
      <Spacer marginBottom={['M16']} />
      <p>Set up alerts and be the first to know when a new ad is listed.</p>
      <Spacer marginBottom={['M16']} />
      <Label htmlFor="save-search-name">Name your saved search</Label>
      <TextField
        id="save-search-name"
        value={name}
        willUseSubText={false}
        onChange={setName}
        maxLength={50}
      />
      <Spacer marginBottom={['M16']} />
      <TagList>
        {terms.map((term) => {
          return (
            <TagListItem key={`saved-search-modal-tag-${term}`}>
              <Tag text={term} textColor="GREY_DARK" bgColor="GREY_LIGHTER" />
            </TagListItem>
          );
        })}
      </TagList>
      <Spacer marginBottom={['M24']} />
      <Prompt>
        <b>How often should we notify you?</b>
      </Prompt>
      <Spacer marginBottom={['M16']} />
      <RadioButton
        name="notifications"
        value={NotificationFrequency.INSTANT}
        label="Instant alerts"
        checked={selected === NotificationFrequency.INSTANT}
        onChange={() => setSelected(NotificationFrequency.INSTANT)}
        disabled={!isInstantAlertAvailable}
      />
      <Spacer marginBottom={['M16']} />
      <RadioButton
        name="notifications"
        value={NotificationFrequency.DAILY}
        label="Daily alerts"
        checked={selected === NotificationFrequency.DAILY}
        onChange={() => setSelected(NotificationFrequency.DAILY)}
      />
      <Spacer marginBottom={['M16']} />
      <RadioButton
        name="notifications"
        value={NotificationFrequency.OFF}
        label="No alerts"
        checked={selected === NotificationFrequency.OFF}
        onChange={() => setSelected(NotificationFrequency.OFF)}
      />
      <Spacer marginBottom={['M32']} />
      <SavedSearchButton
        ofType="PRIMARY"
        data-testid="save-seach-button"
        onClick={onClick}
        disabled={name.length === 0}
      >
        {saving ? (
          <Saving color="LIGHT" cdnUrl={CDN_STATIC_ASSETS} />
        ) : (
          'Save Search'
        )}
      </SavedSearchButton>
    </StyledSavedSearchesModal>
  );
};

export { SavedSearchModal };
