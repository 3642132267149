import Head from 'next/head';
import { NextSeo } from 'next-seo';

import { getVehicleSchemaLD } from 'components/SearchPage/helpers/seo';

import type { Ad, ISearchPageAds } from 'api/types/searchPageApiTypes';
import getConfig from 'next/config';
const {
  publicRuntimeConfig: { DISPLAY_NEW_AD_CARDS },
} = getConfig();

type SearchHeadProps = {
  currentSection: string;
  h1: string;
  title: string;
  description: string;
  url: string;
  isMotor: boolean;
  combinedAds: ISearchPageAds[];
  combinedAdsV2: Ad[];
};

const SearchHead = (props: SearchHeadProps) => {
  const {
    currentSection,
    title,
    description,
    url,
    combinedAds,
    combinedAdsV2,
    h1,
    isMotor,
  } = props;

  const isVehicleSchemaSection =
    isMotor ||
    currentSection === 'vintagecars' ||
    currentSection === 'tractors';

  const hasAds = combinedAds && combinedAds.length > 0;

  let images;
  if (DISPLAY_NEW_AD_CARDS === 'true') {
    images = combinedAdsV2?.[0]?.gallery?.coverImage.large
      ? [
          {
            url: combinedAdsV2?.[0]?.gallery?.coverImage.large,
            width: 600,
            height: 450,
          },
        ]
      : undefined;
  } else {
    images = combinedAds?.[0]?.photos?.[0].large
      ? [
          {
            url: combinedAds[0].photos[0].large,
            width: 600,
            height: 450,
          },
        ]
      : undefined;
  }

  return (
    <>
      {isVehicleSchemaSection && hasAds && (
        <Head>
          <script type="application/ld+json">
            {DISPLAY_NEW_AD_CARDS === 'true'
              ? getVehicleSchemaLD(combinedAdsV2, [], currentSection, h1)
              : getVehicleSchemaLD([], combinedAds, currentSection, h1)}
          </script>
        </Head>
      )}
      <NextSeo
        title={title}
        description={description}
        canonical={url}
        nofollow={!hasAds}
        noindex={!hasAds}
        openGraph={{
          url,
          type: 'product',
          title,
          description,
          images,
        }}
      />
    </>
  );
};

export { SearchHead };
