import BottomSheetList from 'features/filters/components/BottomSheetList/BottomSheetList';
import React from 'react';
import * as Styled from 'features/filters/components/ListSingleSelect/ListSingleSelect.styled';
import type { ListSingleSelectProps } from 'features/filters/components/ListSingleSelect/ListSingleSelect.typed';
import { radioButtonStyles } from 'features/filters/components/ListSingleSelect/ListSingleSelect.styled';

const ListSingleSelect = (props: ListSingleSelectProps) => {
  const {
    options,
    selectedOption,
    onSelectOption,
    name,
    placeholder,
    label,
    onClear,
    isDisabled,
  } = props;
  return (
    <BottomSheetList
      name={name}
      label={label}
      placeholder={placeholder}
      onClear={onClear}
      id={name}
      isDisabled={isDisabled}
    >
      {options.map((option, index) => {
        const isSelected =
          selectedOption && selectedOption.value === option.value;
        const id = `${name}-${option.value}-${index}`;
        return (
          <BottomSheetList.ListItem
            key={id}
            onClick={(e) => {
              e.preventDefault();
              onSelectOption && onSelectOption(option);
            }}
          >
            <Styled.RadioInput
              id={id}
              name={name}
              label={option.label}
              radioButtonStyles={radioButtonStyles}
              value={option.value}
              checked={isSelected}
            />
          </BottomSheetList.ListItem>
        );
      })}
    </BottomSheetList>
  );
};

export { ListSingleSelect };
