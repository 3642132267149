import styled, { css } from 'styled-components';

import { ChevronUpIc, ArrowBackIc } from '@dsch/dd-icons';

const Item = styled.a<{ selected: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => `${theme.spacing.S8} ${theme.spacing.M16}`};
  ${({ theme }) => theme.fontSize.M14};
  border-bottom: 1px solid ${({ theme }) => theme.colors.GREY_LIGHTER};
  color: ${({ theme }) => theme.colors.GREY_DARKER};

  &:hover {
    background: ${({ theme }) => theme.colors.GREY_LIGHTER};
    color: ${({ theme }) => theme.colors.GREY_DARKER};
  }

  border-bottom: none;

  ${({ selected, theme }) =>
    selected &&
    css`
      font-weight: ${({ theme }) => theme.fontWeight.bold};
      background: ${({ theme }) => theme.colors.WHITE};
      border: 2px solid ${theme.colors.GREY_DARKER};
      border-radius: ${({ theme }) => theme.borderRadius.default};
    `};
`;

const Count = styled.span`
  color: ${({ theme }) => theme.colors.GREY};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

const Back = styled.a`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing.S8} ${theme.spacing.M16}`};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  ${({ theme }) => theme.fontSize.M14};
  color: ${({ theme }) => theme.colors.GREY_DARKER};

  &:hover {
    background: ${({ theme }) => theme.colors.GREY_LIGHTER};
    color: ${({ theme }) => theme.colors.GREY_DARKER};
  }
`;

const BackArrow = styled(ArrowBackIc)`
  margin-right: ${({ theme }) => theme.spacing.S8};
`;

const SubsectionsHeading = styled.p`
  ${({ theme }) => theme.fontSize.S12};
  padding: ${({ theme }) => `0 ${theme.spacing.M16}`};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  background: ${({ theme }) => theme.colors.GREY_LIGHTER};
`;

const Collapsible = styled.div<{ collapsed: boolean }>`
  position: relative;

  ${({ collapsed }) =>
    collapsed &&
    css`
      height: 400px;
      overflow: hidden;
    `}
`;

const CollapseButtonIcon = styled(ChevronUpIc)`
  display: inline-block;
  margin-left: ${({ theme }) => theme.spacing.S8};
`;

const CollapseButton = styled.button<{ collapsed: boolean }>`
  position: sticky;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  padding: ${({ theme }) => theme.spacing.S8};
  ${({ theme }) => theme.fontSize.M14};
  text-align: center;
  background: linear-gradient(
    350.17deg,
    ${({ theme }) => theme.colors.OFFWHITE} 50.57%,
    rgba(250, 250, 250, 0) 130.53%
  );
  color: ${({ theme }) => theme.colors.BLUE};
  cursor: pointer;

  ${({ collapsed }) =>
    collapsed &&
    css`
      ${CollapseButtonIcon} {
        transform: rotate(180deg);
      }
    `}
`;

export {
  Item,
  Count,
  Back,
  BackArrow,
  SubsectionsHeading,
  Collapsible,
  CollapseButtonIcon,
  CollapseButton,
};
