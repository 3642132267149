import {
  ISearchPageFilter,
  ISearchPageFilterValue,
  TypePrice,
} from 'api/types/searchPageApiTypes';
import { Checkbox } from 'components/Toolkit/Inputs/Checkbox';
import {
  useFiltersState,
  useFiltersDispatch,
} from 'features/filters/Filters.context';
import debounce from 'lodash/debounce';
import { CURRENCIES } from 'types';
import { ListMultiSelect } from 'features/filters/components/ListMultiSelect/ListMultiSelect';
import {
  mapToMultiSelect,
  mapToPriceSelect,
  mapToSingleSelect,
  mapSelectedOptionsToMultiSelect,
} from 'features/filters/Filters.mapper';
import { ListSingleSelectTag } from 'features/filters/components/ListSingleSelectTag/ListSingleSelectTag';
import { ListMultiSelectTag } from 'features/filters/components/ListMultiSelectTag/ListMultiSelectTag';
import { MakeModel } from 'features/filters/components/MakeModel/MakeModel';
import { TextInputRange } from 'features/filters/components/TextInputRange/TextInputRange';
import { PriceSelectRange } from 'features/filters/components/PriceSelectRange/PriceSelectRange';
import { PriceSelectMonthRange } from 'features/filters/components/PriceSelectMonthRange/PriceSelectMonthRange';
import { ListSingleSelectNative } from 'features/filters/components/ListSingleSelectNative/ListSingleSelectNative';
import { PriceInputRange } from 'features/filters/components/PriceInputRange/PriceInputRange';
import { DropDownRange } from 'features/filters/components/DropDownRange/DropDownRange';
import { LocationFilter } from 'features/filters/components/Location/Location';
import { getFilterValue, getRangeValue } from 'domains/Filter';
import { useLocationReadAndWriteURL } from 'features/location/hooks/LocationReadAndWriteURL/LocationReadAndWriteURL.hook';
import { useURLStateManagement } from 'components/SearchPage/hooks/useURLStateManagement/useURLStateManagement';
import { ListMultiSelectCheckbox } from 'features/filters/components/ListMultiSelectCheckbox/ListMultiSelectCheckbox';
import { ListSingleSelect } from 'features/filters/components/ListSingleSelect/ListSingleSelect';
import type { Option } from 'features/filters/components/ListSingleSelect/ListSingleSelect.typed';

const mapCurrency = (currency?: CURRENCIES) =>
  currency === CURRENCIES.GBP ? '£' : '€';

export interface IFiltersProps {
  dealerId?: string;
}

const Filters = ({ dealerId }: IFiltersProps) => {
  const {
    getFilterValues,
    updateFilter,
    updateRange,
    updateFilterMultiValue,
    deleteFilterMultiValue,
  } = useFiltersDispatch();

  const { filters, ranges, filtersData } = useFiltersState();

  const {
    updateURL,
    radiusQueryValue,
    carFilterQueryValue,
    countyTownQueryValue,
    areaQueryValue,
    makeQueryValue,
    modelQueryValue,
  } = useURLStateManagement();

  const { handleSelect: onSelect } = useLocationReadAndWriteURL({
    areaQueryValue,
    countyTownQueryValue,
    SEOPathParam: carFilterQueryValue,
    applySEOPathParam: Boolean(makeQueryValue && modelQueryValue),
    radiusQueryValue,
    URLCallback: updateURL,
  });

  const getFilterMultiValue = (filter: ISearchPageFilter) => {
    const filterFromUrl = getFilterValues(filter);

    const filterSelected =
      filter.values &&
      filter.values.filter((filterValue) =>
        filterValue.value ? filterFromUrl?.includes(filterValue.value) : false,
      );
    return filterSelected && filterSelected.length > 0
      ? filterSelected
      : filter.values
      ? [filter.values[0]]
      : [];
  };

  const getCheckboxFilterMultiValue = (filter: ISearchPageFilter) => {
    const filterFromUrl = getFilterValues(filter, true);

    const filterSelected = filter.values?.filter((filterValue) =>
      filterValue.value ? filterFromUrl?.includes(filterValue.value) : false,
    );

    return filterSelected && filterSelected.length > 0 ? filterSelected : [];
  };

  const getTextInputDisplayName = (filters: ISearchPageFilterValue[]) => {
    const filtersLength = filters.length;
    const firstFiltersValue = filters[0].value;
    const firstFiltersDisplayName = filters[0].displayName;
    if (filtersLength && firstFiltersValue === '')
      return filters[0].displayName;
    else if (filtersLength > 1)
      return `${firstFiltersDisplayName} (+${filtersLength - 1})`;
    else return firstFiltersDisplayName;
  };

  const handleMultiFilterChange = (
    option: string,
    isChecked: boolean,
    name: string,
  ) => {
    if (isChecked) {
      deleteFilterMultiValue({ name, values: [option] });
    } else {
      option.trim()
        ? updateFilterMultiValue({ name, values: [option] })
        : updateFilter({ name, values: [] });
    }
  };

  const handleMultiFilterClear = (name: string) =>
    updateFilter({ name, values: [''] });

  const handleClearRange = (name: string) => {
    updateRange({ name, from: '', to: '' });
  };

  const handleFilterChange = (name: string, value: string) =>
    updateFilter({ name, values: [value] });

  const handleCheckbox = (name: string, checked: boolean) => {
    if (name === 'containsValidPrice' && !checked) {
      updateFilter({ name: 'containsValidPrice', values: [''] });
    } else {
      handleFilterChange(name, checked.toString());
    }
  };

  const handleRangeValidationChange = (
    name: string,
    from?: string,
    to?: string,
    currency?: CURRENCIES,
    typePrice?: TypePrice,
    carFinance?: string[],
  ) => {
    const rangeToUpdate = {
      name,
      from,
      to: Number(from) > Number(to) ? '' : to,
      currency,
      typePrice,
    };
    const filterToUpdate = carFinance
      ? { name: 'carFinance', values: carFinance }
      : undefined;

    updateRange(rangeToUpdate, filterToUpdate);
  };

  const radiusCallback = (radius: string) =>
    updateURL({
      queryParams: { radius },
    });

  function renderFilter(filter: ISearchPageFilter) {
    switch (filter.filterType.name) {
      case 'Group':
        switch (filter.variant) {
          default:
            return (
              <LocationFilter
                onSelect={onSelect}
                radiusCallback={radiusCallback}
              />
            );
        }
      default:
        switch (filter.filterType.name) {
          case 'ListSingleSelect':
            switch (filter.variant) {
              case 'DONEDEAL_TAG': {
                const options = mapToSingleSelect(
                  filter.values as ISearchPageFilterValue[],
                );
                const value =
                  getFilterValue(filters, filter.name).length > 0
                    ? getFilterValue(filters, filter.name)
                    : options[0].value;
                return (
                  <ListSingleSelectTag
                    name={filter.name}
                    label={filter.displayName}
                    options={options}
                    onChange={(newValue: string) =>
                      handleFilterChange(filter.name, newValue)
                    }
                    value={value}
                  />
                );
              }
              default: {
                const options = mapToSingleSelect(
                  filter.values as ISearchPageFilterValue[],
                );
                const value =
                  getFilterValue(filters, filter.name).length > 0
                    ? getFilterValue(filters, filter.name)
                    : options[0].value;
                return (
                  <ListSingleSelectNative
                    name={filter.name}
                    label={filter.displayName}
                    options={options}
                    onChange={(newValue: string) =>
                      handleFilterChange(filter.name, newValue)
                    }
                    value={value}
                  />
                );
              }
            }
          case 'ListMultiSelect':
            switch (filter.variant) {
              case 'DONEDEAL_TAG': {
                const options = mapToMultiSelect(
                  filter.values as ISearchPageFilterValue[],
                );
                const selectedOptions = mapSelectedOptionsToMultiSelect(
                  getFilterMultiValue(filter),
                );

                return (
                  <ListMultiSelectTag
                    name={filter.name}
                    label={filter.displayName}
                    options={options}
                    selectedOptions={selectedOptions}
                    onSelectOption={(option, isChecked) => {
                      return handleMultiFilterChange(
                        option,
                        isChecked ?? false,
                        filter.name,
                      );
                    }}
                    onClear={() => handleMultiFilterClear(filter.name)}
                  />
                );
              }
              case 'DONEDEAL_MAKE_MODEL': {
                return <MakeModel dealerId={dealerId} />;
              }

              case 'DONEDEAL_CHECKBOX': {
                const options = mapToMultiSelect(
                  filter.values as ISearchPageFilterValue[],
                );
                const selectedOptions = mapSelectedOptionsToMultiSelect(
                  getCheckboxFilterMultiValue(filter),
                );

                return (
                  <ListMultiSelectCheckbox
                    name={filter.name}
                    label={filter.displayName}
                    options={options}
                    selectedOptions={selectedOptions}
                    onSelectOption={(option, isChecked) => {
                      return handleMultiFilterChange(
                        option,
                        isChecked ?? false,
                        filter.name,
                      );
                    }}
                    onClear={() => handleMultiFilterClear(filter.name)}
                  />
                );
              }

              default: {
                const options = mapToMultiSelect(
                  filter.values as ISearchPageFilterValue[],
                );
                const selectedOptions = mapSelectedOptionsToMultiSelect(
                  getFilterMultiValue(filter),
                );

                return (
                  <ListMultiSelect
                    name={filter.name}
                    label={filter.displayName}
                    placeholder={
                      getTextInputDisplayName(getFilterMultiValue(filter)) ?? ''
                    }
                    options={options}
                    selectedOptions={selectedOptions}
                    onSelectOption={(optionValue, isChecked) =>
                      handleMultiFilterChange(
                        optionValue,
                        isChecked,
                        filter.name,
                      )
                    }
                    onClear={() => handleMultiFilterClear(filter.name)}
                  />
                );
              }
            }
          case 'TextInputRange': {
            const rangeValue = getRangeValue(ranges, filter.name);
            return (
              <TextInputRange
                label={filter.displayName}
                value={{
                  to: rangeValue?.to || undefined,
                  from: rangeValue?.from || undefined,
                }}
                onChange={debounce(
                  (from, to) =>
                    updateRange({
                      name: filter.name,
                      from,
                      to,
                    }),
                  500,
                )}
              />
            );
          }
          case 'PriceInputRange':
            switch (filter.variant) {
              case 'DONEDEAL_PRICE_DROPDOWN': {
                const priceRangeValues =
                  filter.values &&
                  (filter.values[0] as unknown as {
                    from: ISearchPageFilterValue[];
                    to: ISearchPageFilterValue[];
                  });
                const rangeValue = getRangeValue(ranges, filter.name);
                const currency = mapCurrency(rangeValue?.currency);
                return (
                  <PriceSelectRange
                    from={{
                      options: mapToPriceSelect(
                        priceRangeValues?.from,
                        currency,
                      ),
                      selectedValue: rangeValue?.from || '',
                    }}
                    to={{
                      options: mapToPriceSelect(priceRangeValues?.to, currency),
                      selectedValue: rangeValue?.to || '',
                    }}
                    value={{
                      to: rangeValue?.to,
                      from: rangeValue?.from,
                      currency: rangeValue?.currency ?? CURRENCIES.EUR,
                    }}
                    onChangePrice={(currency, from, to) =>
                      handleRangeValidationChange(
                        filter.name,
                        from,
                        to,
                        currency,
                      )
                    }
                  />
                );
              }
              case 'DONEDEAL_PRICE_DROPDOWN_PPM': {
                const priceRangePPMDefaultValues =
                  filter.values &&
                  (filter.values[0] as unknown as {
                    from: ISearchPageFilterValue[];
                    pricePerMonthFrom: ISearchPageFilterValue[];
                    to: ISearchPageFilterValue[];
                    pricePerMonthTo: ISearchPageFilterValue[];
                  });
                const rangeValue = getRangeValue(ranges, filter.name);
                const currency = mapCurrency(rangeValue?.currency);
                const carFinanceFilter = filters?.find(
                  (item) => item.name === 'carFinance',
                );
                const typePrice: TypePrice =
                  (carFinanceFilter && carFinanceFilter.values.length
                    ? carFinanceFilter.values[0] === 'true'
                      ? 'pricePerMonth'
                      : 'price'
                    : rangeValue?.typePrice) || 'price';

                return (
                  <PriceSelectMonthRange
                    typePrice={typePrice}
                    from={{
                      options: mapToPriceSelect(
                        priceRangePPMDefaultValues?.from,
                        currency,
                      ),
                      selectedValue: rangeValue?.from || '',
                    }}
                    to={{
                      options: mapToPriceSelect(
                        priceRangePPMDefaultValues?.to,
                        currency,
                      ),
                      selectedValue: rangeValue?.to || '',
                    }}
                    fromMonth={{
                      options: mapToPriceSelect(
                        priceRangePPMDefaultValues?.pricePerMonthFrom,
                        currency,
                      ),
                      selectedValue: rangeValue?.from || '',
                    }}
                    toMonth={{
                      options: mapToPriceSelect(
                        priceRangePPMDefaultValues?.pricePerMonthTo,
                        currency,
                      ),
                      selectedValue: rangeValue?.to || '',
                    }}
                    onChangePrice={(currency, from, to, typePrice) =>
                      handleRangeValidationChange(
                        filter.name,
                        from,
                        to,
                        currency,
                        typePrice,
                      )
                    }
                    onChangeType={(currency, type) => {
                      let newCurrency = currency;
                      if (type === 'pricePerMonth') {
                        // set currency back to eur for PPM
                        newCurrency = CURRENCIES.EUR;
                      }
                      // Reset from and to
                      handleRangeValidationChange(
                        filter.name,
                        undefined,
                        undefined,
                        newCurrency,
                        type,
                        type === 'pricePerMonth' ? ['true'] : [],
                      );
                    }}
                    value={{
                      to: rangeValue?.to || undefined,
                      from: rangeValue?.from || undefined,
                      currency: rangeValue?.currency || undefined,
                      typePrice: rangeValue?.typePrice,
                    }}
                  />
                );
              }
              default:
                const rangeValues = getRangeValue(ranges, filter.name);
                return (
                  <PriceInputRange
                    value={{
                      to: rangeValues?.to || undefined,
                      from: rangeValues?.from || undefined,
                      currency: rangeValues?.currency || 'EUR',
                    }}
                    onChangePrice={debounce((currency, from, to) => {
                      updateRange({
                        name: filter.name,
                        from,
                        to,
                        currency,
                      });
                    }, 500)}
                  />
                );
            }
          case 'DropDownRange': {
            const [values] = filter.values as ISearchPageFilterValue[];
            const { from: fromValues, to: toValues } = values;
            const dropDownRangeFilter = {
              from: fromValues,
              to: toValues,
            };
            const rangeValue = getRangeValue(ranges, filter.name);
            const { from, to } = dropDownRangeFilter;
            return (
              <>
                <DropDownRange
                  label={filter.displayName}
                  name={filter.name}
                  from={{
                    options: mapToSingleSelect(from),
                    selectedValue: rangeValue?.from || '',
                  }}
                  to={{
                    options: mapToSingleSelect(to),
                    selectedValue: rangeValue?.to || '',
                  }}
                  onFromChange={(value: string) =>
                    handleRangeValidationChange(
                      filter.name,
                      value,
                      rangeValue?.to,
                    )
                  }
                  onToChange={(value: string) =>
                    handleRangeValidationChange(
                      filter.name,
                      rangeValue?.from,
                      value,
                    )
                  }
                />
              </>
            );
          }
          case 'SingleSelectRange':
            const rangeValue = getRangeValue(ranges, filter.name);
            let selectValue = '';
            if (rangeValue?.from) selectValue = rangeValue.from;
            else if (rangeValue?.to) selectValue = rangeValue.to;
            else if (filter.values && filter.values[0].value)
              selectValue = filter.values[0].value;

            const options = mapToSingleSelect(
              filter.values as ISearchPageFilterValue[],
            );

            const selectedOption = options.find(
              (option) => option.value === selectValue,
            );

            return (
              <ListSingleSelect
                name={filter.name}
                placeholder={
                  selectedOption ? selectedOption.label : filter.displayName
                }
                label={filter.displayName}
                options={options}
                selectedOption={selectedOption}
                onSelectOption={(newOption: Option) => {
                  const filterSelected = filter.values?.find(
                    (f) => f.value === newOption.value,
                  );

                  if (filterSelected?.type === 'MIN') {
                    updateRange({
                      name: filter.name,
                      from: filterSelected.value,
                    });
                  } else if (filterSelected?.type === 'MAX') {
                    updateRange({
                      name: filter.name,
                      to: filterSelected.value,
                    });
                  }
                }}
                onClear={() => handleClearRange(filter.name)}
              />
            );
          case 'CheckBox': {
            return (
              <Checkbox
                label={filter.displayName}
                name={filter.name}
                onChange={(checked: boolean) =>
                  handleCheckbox(filter.name, checked)
                }
                checked={Boolean(getFilterValue(filters, filter.name))}
              />
            );
          }
          default:
            return null;
        }
    }
  }

  return (
    <div>
      {filtersData && (
        <ul>
          {filtersData?.map((item: ISearchPageFilter, index: number) => (
            <li key={index}>{renderFilter(item)}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

export { Filters };
