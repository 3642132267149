import styled, { css } from 'styled-components';

import { media } from 'helpers/breakpoints';
import { GhostButton } from 'components/Toolkit/Button/GhostButton';
import { InternalLinks } from 'components/Toolkit/InternalLinks/InternalLinks';
import {
  InputContainer,
  SearchItemsContainer,
} from 'components/Toolkit/SearchModal/BaseSearch';

export const Header = styled.div`
  padding: ${({ theme }) => `${theme.spacing.S8} 0`};
  margin-bottom: ${({ theme }) => theme.spacing.M16};
  background: ${({ theme }) => theme.colors.GREY_LIGHTER};

  ${media.medium} {
    padding-bottom: ${({ theme }) => theme.spacing.M16};
  }
`;

export const HeaderDivider = styled.div`
  display: flex;
  flex-direction: column;

  ${media.large} {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
`;

export const HeaderTitle = styled.h1`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 0 1 auto;
  ${({ theme }) => theme.fontSize.L18};
  margin-right: ${({ theme }) => theme.spacing.M16};

  ${media.large} {
    ${({ theme }) => theme.fontSize.H32};
  }
`;

export const HeaderSearch = styled.div`
  flex: 1 0 auto;
  order: -1;
  margin-bottom: ${({ theme }) => theme.spacing.S12};

  ${SearchItemsContainer} {
    top: ${({ theme }) => theme.spacing.L40};
  }

  ${InputContainer} {
    height: ${({ theme }) => theme.spacing.L40};
  }

  ${media.large} {
    ${InputContainer} {
      height: ${({ theme }) => theme.spacing.L48};
    }
    ${SearchItemsContainer} {
      top: ${({ theme }) => theme.spacing.L48};
    }
  }

  ${media.large} {
    order: 1;
    max-width: 456px;
    min-width: 300px;
    margin-bottom: 0;
  }
`;

export const HeaderSections = styled.div`
  order: -1;
  margin-bottom: ${({ theme }) => theme.spacing.S12};
  ${media.large} {
    display: none;
  }
`;

export const Breadcrumbs = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.S12};
  ${media.large} {
    margin: 0;
  }
`;

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.OFFWHITE};
`;

export const Main = styled.div`
  display: block;

  ${media.large} {
    display: grid;
    grid-template-columns: 260px 1fr;
    grid-gap: ${({ theme }) => theme.spacing.L40};
  }
`;

export const Sidebar = styled.div`
  display: none;
  padding-bottom: ${({ theme }) => theme.spacing.M16};

  ${media.large} {
    display: block;
  }
`;

export const SidebarAdUnit = styled.div`
  position: sticky;
  top: ${({ theme }) => theme.spacing.M16};
`;

export const Body = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing.M16};
`;

// TODO: Think of a better name
export const DetailsAndToggles = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing.S8} 0;
  margin-bottom: ${({ theme }) => theme.spacing.M16};

  ${media.medium} {
    flex-direction: row;
    align-items: center;
  }
`;

export const Details = styled.h2`
  flex: 1 1 auto;
  ${({ theme }) => theme.fontSize.M16};
  font-weight: normal;
  word-break: break-word;
`;

export const Toggles = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 0 0 auto;
  order: -1;
  margin-bottom: ${({ theme }) => theme.spacing.M16};

  ${media.medium} {
    display: flex;
    order: 1;
    margin-bottom: 0;
  }
`;

export const ViewTypeButtons = styled.div`
  display: flex;
`;

export const ViewTypeButton = styled(GhostButton)<{ active: boolean }>`
  margin-right: ${({ theme }) => theme.spacing.M16};
  color: ${({ theme }) => theme.colors.GREY_LIGHT};
  ${({ active }) =>
    active &&
    css`
      color: ${({ theme }) => theme.colors.BLUE};
    `};
`;

export const StickyFooter = styled.div`
  ${media.large} {
    display: none;
  }
`;

export const Pagination = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.M24};
`;

export const InternalTagsPanel = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.M24};
`;

export const InternalTagsTitle = styled.h2`
  margin-bottom: ${({ theme }) => theme.spacing.M16};
  ${({ theme }) => theme.fontSize.B18};
`;

export const DFPUnitContainer_Top = styled.div`
  display: none;

  ${media.large} {
    display: block;
    margin-bottom: ${({ theme }) => theme.spacing.M16};
  }
`;

export const Spacer = styled.div`
  min-height: 4px;
  margin-bottom: ${({ theme }) => theme.spacing.M16};
`;

export const FeaturedDealerContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.GREY_DARKER};
  padding: ${({ theme }) => `${theme.spacing.M16} 0`};

  h2 {
    padding-left: 16px;
    padding-right: 16px;
    color: white;
  }

  ${media.medium} {
    h2 {
      padding: 0;
    }
  }

  ${media.large} {
    padding: ${({ theme }) => `${theme.spacing.M24} 0`};
    h2 {
      padding: 0;
    }
  }
`;

export const SInternalLinks = styled(InternalLinks)`
  background-color: ${({ theme }) => theme.colors.OFFWHITE};
  padding: ${({ theme }) => theme.spacing.M16};

  ${media.medium} {
    padding: ${({ theme }) => theme.spacing.M24} 0;
  }
`;

export const InternalLinksContainer = styled.div<{ isWhite: boolean }>`
  background-color: ${({ theme, isWhite }) =>
    isWhite ? theme.colors.WHITE : theme.colors.OFFWHITE};
  ${SInternalLinks} {
    background-color: ${({ theme, isWhite }) =>
      isWhite ? theme.colors.WHITE : theme.colors.OFFWHITE};
  }
`;

export const BuyingCarTipsContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.WHITE};
  padding: ${({ theme }) => theme.spacing.M16};

  ${media.large} {
    padding: ${({ theme }) => `${theme.spacing.M32} 0`};
  }
`;
