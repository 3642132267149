import { AddCircleOutlineIc } from '@dsch/dd-icons';
import { Spacer } from 'features/filters/Filters.styled';
import { Button } from 'components/Toolkit/Button';
import { MakeModelSelector } from 'features/filters/components/MakeModel/components/MakeModelSelector';
import { useMakeModel } from 'features/filters/components/MakeModel/MakeModel.hook';
import { mapModelOptions } from 'features/filters/components/MakeModel/MakeModel.mapper';
import * as MakeModelStyle from 'features/filters/components/MakeModel/MakeModel.styled';
import { MakeModelProps } from 'features/filters/components/MakeModel/MakeModel.typed';

function MakeModel(props: MakeModelProps) {
  const { dealerId, onUpdate, makeModelValue, showTitle = true } = props;
  const {
    onAddAnother,
    makeModelState,
    makeOptions,
    makeModelData,
    editableMakeModel,
    hasAllMakeSelected,
  } = useMakeModel(onUpdate, makeModelValue);

  return (
    <div>
      {makeModelState.length > 0 &&
        makeModelState
          .sort((a, b) => Number(a.isEditable) - Number(b.isEditable))
          .map((makeModelGroup, index) => {
            const makeValue = !!makeModelGroup.makeValue
              ? makeModelGroup.makeValue
              : makeOptions[0].displayName;

            const modelOptions = mapModelOptions(makeModelData, makeValue);

            return (
              index < 3 && (
                <div key={makeModelGroup.id}>
                  {showTitle &&
                    hasAllMakeSelected &&
                    makeModelState.length === 3 &&
                    index === 0 && (
                      <MakeModelStyle.Heading>
                        {' '}
                        Make/Model
                      </MakeModelStyle.Heading>
                    )}

                  {showTitle && makeModelGroup.isEditable && (
                    <MakeModelStyle.Heading> Make/Model</MakeModelStyle.Heading>
                  )}

                  <MakeModelSelector
                    id={makeModelGroup.id}
                    makeOptions={makeOptions}
                    makeValue={makeValue ?? ''}
                    modelValue={makeModelGroup.modelValue}
                    modelOptions={modelOptions}
                    isEditable={makeModelGroup.isEditable}
                    isVisible={makeModelGroup.isVisible}
                    dealerId={dealerId}
                    onUpdate={onUpdate}
                    makeModelValue={makeModelValue}
                  />

                  {!makeModelGroup.isEditable &&
                    !hasAllMakeSelected &&
                    index === 2 &&
                    makeModelState.length !== 3 && (
                      <MakeModelStyle.Heading>
                        {' '}
                        Make/Model
                      </MakeModelStyle.Heading>
                    )}
                </div>
              )
            );
          })}

      {!!editableMakeModel && (
        <>
          <Button
            ofType="SECONDARY"
            icon={<AddCircleOutlineIc />}
            onClick={onAddAnother}
            disabled={!Boolean(editableMakeModel.makeValue)}
          >
            Add another
          </Button>
          <Spacer />
        </>
      )}
    </div>
  );
}

export { MakeModel };
