import { ListItem } from 'components/Toolkit/Inputs/CustomSelect';
import { useEffect, useState } from 'react';
import { searchPageApi } from 'api/searchPageApi';
import {
  mapModelOptions,
  mapModelToOptions,
} from 'features/filters/components/MakeModel/components/MakeModelSelector.mapper';
import { rg4js } from 'helpers/raygun';
import { API_CLIENT_TIMEOUT } from 'utils';
import { PAGE } from 'helpers/pages';

export const useMakeModelSelector = (
  modelOptions: ListItem[],
  modelValue: string[],
  makeValue: string,
  isEditable: boolean,
  currentSectionQueryValue: string,
  dealerId?: string,
) => {
  const [makeExpanded, setMakeExpanded] = useState(false);
  const [openBottomSheet, setOpenBottomSheet] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState(false);

  const [modelDataOptions, setModelDataOptions] = useState<
    { value: string; label: string }[]
  >(mapModelToOptions(modelOptions));

  const newSelectedModelOptions =
    modelValue &&
    modelValue.length > 0 &&
    modelDataOptions.filter((modelDataItem) =>
      modelValue.includes(modelDataItem.label),
    );

  const textLabelModel = (withMake: boolean = false) => {
    if (modelValue && modelValue.length === 1) {
      return withMake ? `${makeValue} ${modelValue[0]}` : modelValue[0];
    } else if (modelValue && modelValue.length > 1) {
      const modelCountText = `(+${modelValue.length - 1})`;
      return withMake
        ? `${makeValue} ${modelValue[0]} ${modelCountText}`
        : `${modelValue[0]} ${modelCountText}`;
    } else {
      return withMake ? `${makeValue} (All Models)` : 'All Models';
    }
  };

  const isModelEnabled =
    !!makeValue &&
    makeValue !== 'All Makes' &&
    modelDataOptions &&
    modelDataOptions.length > 0;

  const fetchModelByMake = async (make: string) => {
    setIsLoading(true);
    try {
      const models = await searchPageApi.getModelByMake(
        currentSectionQueryValue,
        make,
        { accept: 'application/json' },
        dealerId,
        API_CLIENT_TIMEOUT,
      );
      setModelDataOptions(mapModelOptions(models.data));
      setIsLoading(false);
    } catch (e) {
      rg4js('send', {
        error: new Error('Error handling fetchModelByMake'),
        tags: [PAGE.SEARCH],
        customData: {
          message: e.message || 'client_error',
          currentSectionQueryValue: currentSectionQueryValue,
          make: make,
          dealerId: dealerId,
        },
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (makeValue === 'All Makes') {
      setModelDataOptions([]);
    } else {
      if (modelOptions.length > 0) {
        setModelDataOptions(mapModelToOptions(modelOptions));
      } else {
        makeValue && isEditable && fetchModelByMake(makeValue);
      }
    }
  }, [makeValue]);

  useEffect(() => {
    if (newSelectedModelOptions && modelValue.includes('All Models')) {
      setModelDataOptions([]);
    }
  }, [modelValue]);

  const isDisabled = !isLoading && !isModelEnabled;
  const isNotAllModels = textLabelModel() !== 'All Models';

  return {
    isDisabled,
    isNotAllModels,
    isLoading,
    makeExpanded,
    openBottomSheet,
    newSelectedModelOptions,
    modelDataOptions,
    textLabelModel,
    setMakeExpanded,
    setOpenBottomSheet,
  };
};
