import styled from 'styled-components';

import { StickyFooter } from 'components/ToolkitV2/StickyFooter/StickyFooter';
import { SavedSearchButton } from 'components/SearchPage/components/SavedSearchButton/SavedSearchButton';

import { media } from 'helpers/breakpoints';

const StyledStickyFooter = styled(StickyFooter)`
  display: flex;
  justify-content: center;
  pointer-events: none;
  position: fixed;

  padding: ${({ theme }) =>
    `${theme.spacing.S8} ${theme.spacing.M16} ${theme.spacing.M24}`};

  ${media.large} {
    display: none;
  }
`;

const StickyFooterSavedSearchButton = styled(SavedSearchButton)<{
  active?: boolean;
}>`
  width: 146px;
  pointer-events: auto;

  transform: ${({ active }) => (active ? 'translateY(0)' : 'translateY(72px)')};
  transition: transform 0.25s ease-in-out;
`;

export { StyledStickyFooter, StickyFooterSavedSearchButton };
