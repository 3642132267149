import styled from 'styled-components';

const Heading = styled.span`
  display: block;
  ${({ theme }) => theme.fontSize.M16};
  color: ${({ theme }) => theme.colors.GREY_DARK};
  margin-bottom: ${({ theme }) => theme.spacing.S8};
`;

export { Heading };
