import { useFooter } from 'components/SearchPage/components/Footer/Footer.hook';

import {
  StyledStickyFooter,
  StickyFooterSavedSearchButton,
} from 'components/SearchPage/components/Footer/Footer.styled';

type FooterProps = {
  isSavedSearchDisabled: boolean;
  handleOnClickSave: () => Promise<void>;
};

const Footer = (props: FooterProps) => {
  const { isSavedSearchDisabled, handleOnClickSave } = props;

  const { displaySaveSearch } = useFooter();

  return (
    <StyledStickyFooter data-testid="search-sticky-footer">
      {!isSavedSearchDisabled && (
        <StickyFooterSavedSearchButton
          onClick={handleOnClickSave}
          active={displaySaveSearch}
        />
      )}
    </StyledStickyFooter>
  );
};

export { Footer };
