import { useRouter } from 'next/router';

import type { ParsedUrlQuery } from 'querystring';

import { useQuickFilterContext } from 'components/SearchPage/features/QuickFilters/QuickFilters.context';

import type { FilterPropsReturnType } from 'components/SearchPage/features/QuickFilters/FilterProps.hook.typed';

import { asFrom, asString, asTo } from 'utils/query-parameters';

import { CURRENCIES } from 'types';

const useFilterProps = () => {
  const { onChangeFilterValue, selectedFilterQueryValue } =
    useQuickFilterContext();
  const { query } = useRouter();

  const getFilterProps = (
    filterType: string,
    filterName: string,
  ): FilterPropsReturnType => {
    switch (filterType) {
      case 'ListSingleSelect':
        return {
          onChange: singleSelectCallback(filterName),
          queryValue: { [filterName]: asString(query[filterName]) },
          value: asString(query[filterName]),
          defaultQueryValue: { [filterName]: '' },
        };
      case 'ListMultiSelect':
        return {
          onChange: listMultiSelectCallback(filterName),
          queryValue: { [filterName]: query[filterName] },
          value: query[filterName],
          defaultQueryValue: { [filterName]: '' },
        };
      case 'PriceInputRange':
        return {
          onChange: priceCallback,
          queryValue: formatPriceQueryValue(query),
          value: formatInitialPriceFilterValue(query),
          defaultQueryValue: {
            price_from: '',
            price_to: '',
            sterlingPrice_from: '',
            sterlingPrice_to: '',
            pricePerMonth_from: '',
            pricePerMonth_to: '',
            carFinance: '',
          },
        };
      case 'DropDownRange':
        return {
          onChange: dropDownRangeCallback(filterName),
          queryValue: formatRangeQueryValue(filterName, query),
          value: formatRangeValue(filterName, query),
          defaultQueryValue: {
            [asFrom(filterName)]: '',
            [asTo(filterName)]: '',
          },
        };
      case 'TextInputRange':
        return {
          queryValue: formatRangeQueryValue(filterName, query),
          onChange: dropDownRangeCallback(filterName),
          value: formatRangeValue(filterName, query),
          defaultQueryValue: {
            [asFrom(filterName)]: '',
            [asTo(filterName)]: '',
          },
        };
      case 'SingleSelectRange':
        return {
          queryValue: formatSingleSelectRangeValue(filterName, query),
          onChange: singleSelectRangeCallback(filterName),
          value: formatRangeValue(filterName, query),
          defaultQueryValue: { [asFrom(filterName)]: '' },
        };
      case 'CheckBox':
        return {
          queryValue: { [filterName]: asString(query[filterName]) },
          onChange: singleSelectCallback(filterName),
          value: asString(query[filterName]),
          defaultQueryValue: { [filterName]: '' },
        };
      default:
        return {
          onChange: () => null,
          value: undefined,
          queryValue: undefined,
          defaultQueryValue: undefined,
        };
    }
  };

  const dropDownRangeCallback =
    (filterName: string) => (value: Array<string>) => {
      const keyFrom = asFrom(filterName);
      const keyTo = asTo(filterName);
      const [from, to] = value;
      onChangeFilterValue({
        queryValue: {
          [keyFrom]: from,
          [keyTo]: to,
        },
        filterValue: [from, to],
      });
    };

  const formatRangeQueryValue = (filterName: string, query: ParsedUrlQuery) => {
    const keyFrom = asFrom(filterName);
    const keyTo = asTo(filterName);
    const from = asString(query[keyFrom]) ?? '';
    const to = asString(query[keyTo]) ?? '';

    return {
      [keyFrom]: from,
      [keyTo]: to,
    };
  };

  const formatRangeValue = (filterName: string, query: ParsedUrlQuery) => {
    const keyFrom = asFrom(filterName);
    const keyTo = asTo(filterName);
    const from = asString(query[keyFrom]) ?? '';
    const to = asString(query[keyTo]) ?? '';
    return [from, to];
  };

  const singleSelectCallback = (filterName: string) => (value: string) =>
    onChangeFilterValue({
      queryValue: { [filterName]: value },
      filterValue: value,
    });

  const listMultiSelectCallback = (filterName: string) => (value: string) => {
    const formattedValue = selectedFilterQueryValue
      ? selectedFilterQueryValue[filterName]
      : undefined;

    if (value == '') {
      onChangeFilterValue({
        queryValue: { [filterName]: undefined },
        filterValue: undefined,
      });
    } else if (Array.isArray(formattedValue)) {
      formatMultiSelectArrayValue(formattedValue, value, filterName);
    } else if (
      typeof formattedValue === 'string' &&
      formattedValue.length > 0
    ) {
      formatMultiSelectStringValue(formattedValue, value, filterName);
    } else {
      onChangeFilterValue({
        queryValue: {
          [filterName]: value,
        },
        filterValue: value,
      });
    }
  };

  const formatMultiSelectArrayValue = (
    formattedvalue: Array<string>,
    value: string,
    filterName: string,
  ) => {
    if (formattedvalue.includes(value)) {
      const nonMatchingValues = formattedvalue.filter(
        (item: string) => item !== value,
      );
      if (nonMatchingValues.length > 1) {
        onChangeFilterValue({
          queryValue: { [filterName]: nonMatchingValues },
          filterValue: nonMatchingValues,
        });
      } else {
        const [matchingValue] = nonMatchingValues;
        onChangeFilterValue({
          queryValue: {
            [filterName]: matchingValue,
          },
          filterValue: matchingValue,
        });
      }
    } else {
      onChangeFilterValue({
        queryValue: {
          [filterName]: [...(formattedvalue ?? []), value],
        },
        filterValue: [...(formattedvalue ?? []), value],
      });
    }
  };

  const formatMultiSelectStringValue = (
    formattedvalue: string,
    value: string,
    filterName: string,
  ) => {
    if (formattedvalue === value) {
      onChangeFilterValue({
        queryValue: { [filterName]: undefined },
        filterValue: undefined,
      });
    } else {
      onChangeFilterValue({
        queryValue: {
          [filterName]: [formattedvalue, value],
        },
        filterValue: [formattedvalue, value],
      });
    }
  };

  const formatPriceQueryValue = (query: ParsedUrlQuery) => {
    const price_from = asString(query.price_from) ?? '';
    const price_to = asString(query.price_to) ?? '';
    const sterlingPrice_from = asString(query.sterlingPrice_from) ?? '';
    const sterlingPrice_to = asString(query.sterlingPrice_to) ?? '';
    const pricePerMonth_from = asString(query.pricePerMonth_from) ?? '';
    const pricePerMonth_to = asString(query.pricePerMonth_to) ?? '';
    const carFinance = asString(query.carFinance) ?? '';

    return {
      price_from,
      price_to,
      sterlingPrice_from,
      sterlingPrice_to,
      pricePerMonth_from,
      pricePerMonth_to,
      carFinance,
    };
  };

  const formatInitialPriceFilterValue = (query: ParsedUrlQuery) => {
    const price_from = asString(query.price_from) ?? '';
    const price_to = asString(query.price_to) ?? '';
    const sterlingPrice_from = asString(query.sterlingPrice_from) ?? '';
    const sterlingPrice_to = asString(query.sterlingPrice_to) ?? '';
    const pricePerMonth_from = asString(query.pricePerMonth_from) ?? '';
    const pricePerMonth_to = asString(query.pricePerMonth_to) ?? '';
    const carFinance = asString(query.carFinance) ?? '';
    const currency =
      sterlingPrice_from || sterlingPrice_to ? CURRENCIES.GBP : CURRENCIES.EUR;

    const from = (price_from || sterlingPrice_from) ?? '';
    const to = (price_to || sterlingPrice_to) ?? '';
    const PPMFrom = pricePerMonth_from;
    const PPMTo = pricePerMonth_to;

    return [from, to, PPMFrom, PPMTo, currency, carFinance];
  };

  const priceCallback = (value: Array<string>) => {
    const [fromValue, toValue, currency, typePrice = 'price'] = value;

    const price = typePrice === 'price';
    const pricePerMonth = typePrice === 'pricePerMonth';
    const isEuro = currency === CURRENCIES.EUR;
    const isGBP = currency === CURRENCIES.GBP;

    const price_from = price && isEuro ? fromValue : '';
    const price_to = price && isEuro ? toValue : '';
    const sterlingPrice_from = price && isGBP ? fromValue : '';
    const sterlingPrice_to = price && isGBP ? toValue : '';
    const pricePerMonth_from = pricePerMonth ? fromValue : '';
    const pricePerMonth_to = pricePerMonth ? toValue : '';
    const carFinance = typePrice === 'pricePerMonth' ? 'true' : '';

    const from = (price_from || sterlingPrice_from) ?? '';
    const to = (price_to || sterlingPrice_to) ?? '';
    const PPMFrom = pricePerMonth_from;
    const PPMTo = pricePerMonth_to;

    onChangeFilterValue({
      queryValue: {
        price_from,
        price_to,
        sterlingPrice_from,
        sterlingPrice_to,
        pricePerMonth_from,
        pricePerMonth_to,
        carFinance,
      },
      filterValue: [from, to, PPMFrom, PPMTo, currency, carFinance],
    });
  };

  const singleSelectRangeCallback =
    (filterName: string) => (value: Array<string>) => {
      const keyFrom = asFrom(filterName);

      onChangeFilterValue({
        queryValue: {
          [keyFrom]: value,
        },
        filterValue: value,
      });
    };

  const formatSingleSelectRangeValue = (
    filterName: string,
    query: ParsedUrlQuery,
  ) => {
    const keyFrom = asFrom(filterName);
    const from = asString(query[keyFrom]) ?? '';

    return {
      [keyFrom]: from,
    };
  };

  return { getFilterProps };
};

export { useFilterProps };
