import { useEffect } from 'react';
import type { DependencyList } from 'react';
import { rg4js } from 'helpers/raygun';

// Credit to Louis Li: https://louisrli.github.io/blog/2020/06/04/react-dynamic-script-hook/#.YJzjRmZKjUI

function useScript(
  scriptUrl: string,
  scriptId: string,
  deps: DependencyList,
  callback?: () => void,
  appendToBody: boolean = false,
) {
  useEffect(() => {
    try {
      const scriptOnPage = document.getElementById(scriptId);

      if (deps.some((dep) => dep) && !scriptOnPage) {
        const script = document.createElement('script');
        script.src = scriptUrl;
        script.id = scriptId;
        appendToBody
          ? document.body.appendChild(script)
          : document.getElementsByTagName('head')[0].appendChild(script);

        script.onload = () => {
          callback && callback();
        };
      }

      if (scriptOnPage && callback) {
        callback();
      }

      return () => {
        if (scriptOnPage && callback) {
          scriptOnPage.remove();
        }
      };
    } catch (e) {
      rg4js('send', {
        error: e,
        message: `Error loading script ${scriptUrl} `,
        metadata: {
          scriptUrl,
          scriptId,
          deps,
        },
      });
    }
  }, deps);
}

export { useScript };
