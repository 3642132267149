import { Location } from 'features/location/Location';
import { Label } from 'components/Toolkit/Inputs/Label';

import type { LocationFilterProps } from 'features/filters/components/Location/Location.typed';

import * as Styled from 'features/filters/components/Location/Location.styled';

import type { DisplayContainerConfig } from 'components/Support/DisplayContainer/DisplayContainer';

const LocationFilter = (props: LocationFilterProps) => {
  const { displayLabel = true, onSelect, radiusCallback } = props;

  const bottomSheetConfig: DisplayContainerConfig = {
    small: 'block',
    medium: 'block',
    large: 'none',
  };
  const dropdownConfig: DisplayContainerConfig = {
    small: 'none',
    medium: 'none',
    large: 'block',
  };

  return (
    <Styled.Container>
      {displayLabel && <Label>Location</Label>}
      <Location
        displayInline
        skeletonListLength={12}
        maxHeight="424px"
        maxTextContainerWidthWeb="228px"
        onSelect={onSelect}
        bottomSheetConfig={bottomSheetConfig}
        dropdownConfig={dropdownConfig}
        radiusCallback={radiusCallback}
      />
    </Styled.Container>
  );
};

export { LocationFilter };
