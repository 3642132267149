import React from 'react';
import { CustomSelect, ListItem } from 'components/Toolkit/Inputs/CustomSelect';
import { Dropdown } from 'components/Toolkit/Dropdown/Dropdown';
import { CloseOutlineIc } from '@dsch/dd-icons';
import { InputButton } from 'components/Toolkit/InputButton/InputButton';
import { BottomSheet } from 'components/Toolkit/BottomSheet/BottomSheet';
import { ListMultiSelect } from 'features/filters/components/ListMultiSelect/ListMultiSelect';
import { useMakeModel } from 'features/filters/components/MakeModel/MakeModel.hook';
import * as MakeModelSelectorStyle from 'features/filters/components/MakeModel/components/MakeModelSelector.styled';
import { useMakeModelSelector } from 'features/filters/components/MakeModel/components/MakeModelSelector.hook';
import { MakeModelItem } from 'features/filters/components/MakeModel/MakeModel.typed';

type MakeModelOptions = ListItem[];
export interface MakeModelSelectorPropsType {
  id: string;
  makeValue: string;
  makeOptions: MakeModelOptions;
  modelValue: string[];
  modelOptions: MakeModelOptions;
  isEditable: boolean;
  isVisible: boolean;
  onUpdate?: (href: string, as: string) => void;
  dealerId?: string;
  makeModelValue?: MakeModelItem[];
}

const MakeModelSelector = ({
  id,
  makeValue,
  makeOptions,
  modelValue,
  modelOptions,
  isEditable,
  isVisible,
  onUpdate,
  dealerId,
  makeModelValue,
}: MakeModelSelectorPropsType) => {
  const {
    currentSectionQueryValue,
    onAddModel,
    onMakeChange,
    onRemoveModel,
    onRemove,
    onClear,
  } = useMakeModel(onUpdate, makeModelValue);

  const {
    isNotAllModels,
    isLoading,
    isDisabled,
    makeExpanded,
    openBottomSheet,
    newSelectedModelOptions,
    modelDataOptions,
    textLabelModel,
    setMakeExpanded,
    setOpenBottomSheet,
  } = useMakeModelSelector(
    modelOptions,
    modelValue,
    makeValue,
    isEditable,
    currentSectionQueryValue,
    dealerId,
  );

  const getFilterItemSelected = () => {
    const itemText = textLabelModel(true);
    const items = itemText && itemText.split(' ');
    const hasModelsNumbers = items[items.length - 1].includes('(+');

    const removeButton = (
      <MakeModelSelectorStyle.DeleteButton onClick={() => onRemove(id)}>
        <CloseOutlineIc width="1em" height="1em" />
      </MakeModelSelectorStyle.DeleteButton>
    );

    if (hasModelsNumbers) {
      return (
        <MakeModelSelectorStyle.SButton ofType="SECONDARY">
          <MakeModelSelectorStyle.TitleContainer>
            <span className="title-ellipsis">
              {itemText.replace(items[items.length - 1], '')}
            </span>
            <span className="title-number">{items[items.length - 1]}</span>
            {removeButton}
          </MakeModelSelectorStyle.TitleContainer>
        </MakeModelSelectorStyle.SButton>
      );
    } else {
      return (
        <MakeModelSelectorStyle.SButton ofType="SECONDARY">
          <MakeModelSelectorStyle.TextContainer textContainerMaxWidth="228px">
            {textLabelModel(true)}
          </MakeModelSelectorStyle.TextContainer>
          {removeButton}
        </MakeModelSelectorStyle.SButton>
      );
    }
  };

  if (!isVisible && !isEditable) return null;

  const [firstModelDataOption] = modelDataOptions;

  return (
    <MakeModelSelectorStyle.Wrapper data-testid="make-model-dropdowns" key={id}>
      {isEditable ? (
        <>
          <MakeModelSelectorStyle.Mobile
            data-testid="mobile-make-dropdown"
            isDisabled={isDisabled}
          >
            {isNotAllModels && <MakeModelSelectorStyle.GlobalStyle />}
            <InputButton
              isActive={openBottomSheet}
              value={makeValue}
              onClick={() => !isLoading && setOpenBottomSheet(true)}
              placeholder={'Make'}
              isLoading={isLoading}
            />
            <BottomSheet
              isOpen={openBottomSheet}
              askToClose={() => setOpenBottomSheet(false)}
              headerText={'Make'}
              onApply={() => setOpenBottomSheet(false)}
            >
              <CustomSelect
                listData={makeOptions}
                onSelect={({
                  displayName,
                }: {
                  value: string;
                  displayName: string;
                }) => {
                  onMakeChange && onMakeChange(id, displayName);
                  setOpenBottomSheet(false);
                }}
              />
            </BottomSheet>
          </MakeModelSelectorStyle.Mobile>
          <MakeModelSelectorStyle.Desktop isDisabled={isDisabled}>
            <Dropdown
              data-testid="desktop-make-dropdown"
              expanded={makeExpanded}
              setExpanded={setMakeExpanded}
              displayedValue={makeValue}
              placeholder={'Make'}
            >
              <CustomSelect
                listData={makeOptions}
                onSelect={({
                  displayName,
                }: {
                  value: string;
                  displayName: string;
                }) => {
                  onMakeChange && onMakeChange(id, displayName);
                  setMakeExpanded(false);
                }}
              />
            </Dropdown>
          </MakeModelSelectorStyle.Desktop>
          <ListMultiSelect
            name={id}
            options={modelDataOptions}
            selectedOptions={
              newSelectedModelOptions && newSelectedModelOptions.length > 0
                ? newSelectedModelOptions.map((option) => option.value)
                : [firstModelDataOption ? firstModelDataOption.value : '']
            }
            placeholder={textLabelModel()}
            onSelectOption={(itemValue, isChecked) => {
              if (isChecked) {
                return onRemoveModel(id, itemValue);
              }
              onAddModel(id, itemValue);
            }}
            onClear={() => onClear(id)}
            isLoading={isLoading}
            isDisabled={isDisabled}
            bottomSheetClassName={
              isNotAllModels ? 'with-model' : 'with-no-model'
            }
          />
        </>
      ) : (
        getFilterItemSelected()
      )}
    </MakeModelSelectorStyle.Wrapper>
  );
};

export { MakeModelSelector };
