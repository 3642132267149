import styled, { createGlobalStyle } from 'styled-components';
import { Button } from 'components/Toolkit/Button';
import { media } from 'helpers/breakpoints';
import { ListWrapper } from 'features/filters/components/BottomSheetList/BottomSheetList.styled';

const SButton = styled(Button)`
  margin-bottom: ${({ theme }) => theme.spacing.S12};
  font-weight: normal;
  display: flex;
  height: 40px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 12px 16px;
  box-shadow: none;
  color: #333333;

  &:hover {
    border: 1px solid #333333;
    background-image: none;
    color: #333333;
    box-shadow: none;
    cursor: auto;
  }

  &:active {
    background-image: none;
    box-shadow: none;
    color: #333333;
  }
`;

const DeleteButton = styled.div`
  cursor: pointer;
  svg {
    color: ${({ theme }) => theme.colors.BLUE};
    margin-top: -3px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  .title-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .title-number {
    padding: ${({ theme }) => `0 ${theme.spacing.M24} 0 ${theme.spacing.S2}`};
  }
  ${DeleteButton} {
    position: absolute;
    right: 15px;
  }
`;

const Wrapper = styled.div`
  ${ListWrapper} {
    margin-top: ${({ theme }) => theme.spacing.M16};
  }
`;

const Mobile = styled.div<{ isDisabled?: boolean }>`
  display: block;
  ${media.large} {
    display: none;
  }
`;

const Desktop = styled.div<{ isDisabled?: boolean }>`
  display: none;
  ${media.large} {
    display: block;
  }
`;

const GlobalStyle = createGlobalStyle`
  .with-model .bottom-sheet-header-text {
    color: ${({ theme }) => theme.colors.GREY_DARKER};
  }
`;

const TextContainer = styled.span<{ textContainerMaxWidth: string }>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;

  ${media.large} {
    max-width: ${({ textContainerMaxWidth }) => textContainerMaxWidth};
  }
`;

export {
  TextContainer,
  GlobalStyle,
  Wrapper,
  SButton,
  DeleteButton,
  TitleContainer,
  Mobile,
  Desktop,
};
