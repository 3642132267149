import { useState } from 'react';
import { useRouter } from 'next/router';

import type { UseSectionBrowserProps } from 'components/SearchPage/components/SectionBrowser/SectionBrowser.hook.typed';

import { asString } from 'utils/query-parameters';

const useSectionBrowser = (props: UseSectionBrowserProps) => {
  const { sections, collapsible, currentSection } = props;

  const [collapsed, setCollapsed] = useState(true);

  const filteredSubsections = sections.filter(
    (section) => section.searchMatches && section.searchMatches > 0,
  );
  const showSubsections = filteredSubsections.length > 0;
  const SECTION_DISPLAY_LIMIT = 10;
  const shouldCollapse =
    collapsible && filteredSubsections.length > SECTION_DISPLAY_LIMIT;

  const { asPath } = useRouter();

  const getLink = (name: string) => {
    const path = asString(asPath);
    return path ? path.replace(currentSection, name) : '';
  };

  return {
    collapsed,
    setCollapsed,
    shouldCollapse,
    getLink,
    showSubsections,
    filteredSubsections,
  };
};

export { useSectionBrowser };
