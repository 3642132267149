import { useReducer } from 'react';

import type { ISearchPageFilter } from 'api/types/searchPageApiTypes';
import type {
  ActionType,
  StateType,
} from 'components/SearchPage/features/QuickFilters/QuickFilters.reducer.typed';
import { ACTION_TYPE } from 'components/SearchPage/features/QuickFilters/QuickFilters.reducer.typed';
import { ParsedUrlQuery } from 'querystring';

const quickFiltersReducer = (state: StateType, action: ActionType) => {
  switch (action.type) {
    case ACTION_TYPE.SELECT_QUICK_FILTER:
      return {
        ...state,
        currentFilter: action.payload,
        isFilterBottomSheetOpen: true,
      };
    case ACTION_TYPE.ON_CLOSE_FILTER_DISPLAY:
      return {
        ...state,
        currentFilter: undefined,
        isFilterBottomSheetOpen: false,
        selectedFilterQueryValue: undefined,
      };
    case ACTION_TYPE.ON_CHANGE_FILTER_VALUE:
      return {
        ...state,
        selectedFilterValue: action.payload.filterValue,
        selectedFilterQueryValue: action.payload.queryValue,
      };
    default:
      return state;
  }
};

const useQuickFiltersReducer = () => {
  const [state, dispatch] = useReducer(quickFiltersReducer, {
    isFilterBottomSheetOpen: false,
    currentFilter: undefined,
    selectedFilterQueryValue: undefined,
  });

  const selectQuickFilter = (filter: ISearchPageFilter) =>
    dispatch({
      type: ACTION_TYPE.SELECT_QUICK_FILTER,
      payload: filter,
    });

  const onCloseFilterDisplay = () =>
    dispatch({
      type: ACTION_TYPE.ON_CLOSE_FILTER_DISPLAY,
    });

  const onChangeFilterValue = (args: {
    queryValue: ParsedUrlQuery;
    filterValue?: string | Array<string>;
  }) => {
    const { queryValue, filterValue } = args;
    dispatch({
      type: ACTION_TYPE.ON_CHANGE_FILTER_VALUE,
      payload: {
        queryValue,
        filterValue,
      },
    });
  };

  return {
    ...state,
    selectQuickFilter,
    onCloseFilterDisplay,
    onChangeFilterValue,
  };
};

export { useQuickFiltersReducer };
