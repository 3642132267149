import type { ParsedUrlQuery } from 'querystring';

import { asString } from 'utils/query-parameters';

import type {
  ISearchPageFilter,
  ISearchPageFilterValue,
  TSearchPageFilterValueCategory,
} from 'api/types/searchPageApiTypes';
import {
  getMultiOptionDisplay,
  getMultiOptionDisplayVariant,
  getPriceDisplay,
  getRangeDisplay,
  getSingleOptionDisplay,
} from 'components/SearchPage/helpers/formatting';

const useQuickFilterFormatting = () => {
  const getFilterCopy = (filter: ISearchPageFilter, query: ParsedUrlQuery) => {
    switch (filter.filterType.name) {
      case 'ListSingleSelect':
        return getQuickFilterDisplay(filter.values, query[filter.name]);
      case 'ListMultiSelect':
        return getListMultiSelectDisplay(filter, query);
      case 'PriceInputRange':
        return getPriceDisplay({
          price_from: asString(query.price_from || query.pricePerMonth_from),
          price_to: asString(query.price_to || query.pricePerMonth_to),
          sterlingPrice_from: asString(query.sterlingPrice_from),
          sterlingPrice_to: asString(query.sterlingPrice_to),
        });

      case 'DropDownRange': {
        const fromValues = filter.values ? filter.values[0].from ?? [] : [];
        const toValues = filter.values ? filter.values[0].to ?? [] : [];

        const from = getQuickFilterDisplay(
          fromValues,
          query[`${filter.name}_from`],
        );

        const to = getQuickFilterDisplay(toValues, query[`${filter.name}_to`]);

        return getRangeDisplay({ from, to });
      }
      case 'CheckBox':
        return getQuickFilterDisplay(filter.values, query[filter.name]);
      case 'TextInputRange':
        return getRangeDisplay({
          from: asString(query[`${filter.name}_from`]),
          to: asString(query[`${filter.name}_to`]),
        });
      case 'SingleSelectRange':
        return getRangeDisplay({
          from: asString(query[`${filter.name}_from`]),
        });
      default:
        return undefined;
    }
  };

  const getQuickFilterDisplay = (
    values: Array<
      ISearchPageFilterValue | TSearchPageFilterValueCategory
    > | null,
    queryValue: string | Array<string> | undefined,
  ) => {
    const target = asString(queryValue);

    return getSingleOptionDisplay(values ?? [], target);
  };

  const getListMultiSelectDisplay = (
    filter: ISearchPageFilter,
    query: ParsedUrlQuery,
  ) => {
    if (
      filter.variant === 'DONEDEAL_TAG' ||
      filter.variant === 'DONEDEAL_CHECKBOX'
    ) {
      return getQuickFilterMultiDisplayVariant(
        filter.values,
        query[filter.name],
        filter.displayName,
      );
    } else {
      return query[filter.name]
        ? getQuickFilterMultiDisplay(filter.values ?? [], query[filter.name])
        : filter.displayName;
    }
  };

  const getQuickFilterMultiDisplayVariant = (
    values: Array<ISearchPageFilterValue> | null,
    queryValue: string | Array<string> | undefined,
    filterDisplayName: string,
  ) => {
    if (values) {
      const displayValue = Array.isArray(queryValue)
        ? getMultiOptionDisplayVariant(values, queryValue, filterDisplayName)
        : getQuickFilterDisplay(values, queryValue);
      return displayValue;
    } else return undefined;
  };

  const getQuickFilterMultiDisplay = (
    values: Array<ISearchPageFilterValue> | null,
    queryValue?: string | Array<string>,
  ) => {
    if (values && queryValue) {
      const displayValue = Array.isArray(queryValue)
        ? getMultiOptionDisplay(values, queryValue)
        : getMultiOptionDisplay(values, [queryValue]);
      return displayValue;
    } else return undefined;
  };

  return {
    getFilterCopy,
  };
};

export { useQuickFilterFormatting };
