import styled from 'styled-components';
import { Select, StrictOption } from 'components/Toolkit/Inputs/Select';
import { Label } from 'components/Toolkit/Inputs/Label';
import { mapOptionsRanges } from 'features/filters/Filters.mapper';
import { HeightVariant } from 'components/Toolkit/Inputs/types';

type DropDownValue = {
  options: StrictOption[];
  selectedValue: string;
};

export interface IDropDownRange {
  label: string;
  name: string;
  from: DropDownValue;
  to: DropDownValue;
  onFromChange: (from: string | undefined) => void;
  onToChange: (to: string | undefined) => void;
  heightVariant?: HeightVariant;
}

export const DropDownRangeContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;

  select {
    margin-bottom: ${({ theme }) => theme.spacing.S4};
  }
  .divider {
    background: ${({ theme }) => theme.colors.GREY};
    width: 1px;
    height: 40px;
  }

  div:first-child {
    > select {
      border-right: 0;
      border-radius: ${({ theme }) =>
        `${theme.borderRadius.default} 0 0 ${theme.borderRadius.default}`};
    }
  }

  div:last-child {
    > select {
      border-left: 0;
      border-radius: ${({ theme }) =>
        `0 ${theme.borderRadius.default} ${theme.borderRadius.default} 0`};
    }
  }
`;

function DropDownRange({
  label,
  name,
  from,
  to,
  onFromChange,
  onToChange,
  heightVariant = 'DEFAULT',
}: IDropDownRange) {
  return (
    <div>
      <Label htmlFor="search-price-filter-for">{label}</Label>
      <DropDownRangeContainer>
        <Select
          onChange={onFromChange}
          name={`from-${name}`}
          value={from.selectedValue}
          options={from.options}
          heightVariant={heightVariant}
        />
        <span className="divider" />
        <Select
          onChange={onToChange}
          name={`to-${name}`}
          value={to.selectedValue}
          options={mapOptionsRanges(from, to)}
          heightVariant={heightVariant}
        />
      </DropDownRangeContainer>
    </div>
  );
}

export { DropDownRange };
