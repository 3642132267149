import { ListItem } from 'components/Toolkit/Inputs/CustomSelect';
import { IGetSearchFilterMakeModel } from 'api/types/searchPageApiTypes';

export const mapModelToOptions = (makeModels: ListItem[]) =>
  makeModels
    .map((item: ListItem) => ({
      value: item.value ?? '',
      label: item.displayName ?? '',
    }))
    .filter((item) => item.label !== 'Any');

export const mapModelOptions = (modelData: IGetSearchFilterMakeModel) => {
  const modelsAll = modelData.all ? mapModelToOptions(modelData.all) : [];
  const modelsPopular = modelData.popular
    ? mapModelToOptions(modelData.popular)
    : [];
  return modelsPopular.length > 0
    ? [
        { label: 'All Models', value: '' },
        { label: 'Popular', value: '' },
        ...modelsPopular,
        { label: 'Models list', value: '' },
        ...modelsAll,
      ]
    : [
        { label: 'All Models', value: '' },
        { label: 'Models list', value: '' },
        ...modelsAll,
      ];
};
