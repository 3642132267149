import * as Styled from 'components/SearchPage/components/Listings/components/SearchCardV2/SearchCardV2.styled';
import type { SearchCardV2Props } from 'components/SearchPage/components/Listings/components/SearchCardV2/SearchCardV2.typed';

function SearchCardV2(props: SearchCardV2Props) {
  return (
    <Styled.Wrapper>
      SearchCardV2
      <small>
        <pre>{JSON.stringify(props, null, 2)}</pre>
      </small>
    </Styled.Wrapper>
  );
}

export { SearchCardV2 };
