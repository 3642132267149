import styled from 'styled-components';

import { InfoTitle } from 'components/Toolkit/InfoTitle/InfoTitle';
import { InfoListItem } from 'components/Toolkit/InfoListItem/InfoListItem';
import { media } from 'helpers/breakpoints';
import { CheckmarkIc } from '@dsch/dd-icons';

import { BaseModal } from 'components/Toolkit/Modal/BaseModal';

const SBaseModal = styled(BaseModal)`
  overflow-x: hidden;
`;

const IntroText = styled.p`
  ${({ theme }) => theme.fontSize.M16};
`;

const Header = styled(InfoTitle)`
  margin-bottom: ${({ theme }) => theme.spacing.M16};
  ${media.large} {
    padding: 0;
  }
`;

const HeaderText = styled.p`
  ${({ theme }) => theme.fontSize.L20};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

const BodyText = styled.p`
  ${({ theme }) => theme.fontSize.B14};
  margin-bottom: ${({ theme }) => theme.spacing.M16};
`;

const OpeningText = styled.p`
  ${({ theme }) => theme.fontSize.M14};
  margin-bottom: ${({ theme }) => theme.spacing.M16};
`;

const ClosingText = styled.p`
  ${({ theme }) => theme.fontSize.M14};
  color: ${({ theme }) => theme.colors.GREY_DARKER};
  margin-bottom: ${({ theme }) => theme.spacing.M16};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SIoCheckmark = styled(CheckmarkIc)`
  color: ${({ theme }) => theme.colors.GREEN};
  width: 16px;
  height: 16px;
`;

const SInfoListItem = styled(InfoListItem)`
  margin-bottom: ${({ theme }) => theme.spacing.M16};
`;

export {
  SBaseModal,
  IntroText,
  Header,
  HeaderText,
  BodyText,
  OpeningText,
  ClosingText,
  ButtonContainer,
  SIoCheckmark,
  SInfoListItem,
};
