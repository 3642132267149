import styled from 'styled-components';

const Checkboxes = styled.fieldset`
  ${({ theme }) => theme.fontSize.M16};
  color: ${({ theme }) => theme.colors.GREY_DARKER};
  display: flex;
  flex-direction: column;

  border: 0;
  padding: 0;
  margin: 0;

  legend {
    ${({ theme }) => theme.fontSize.M16};
    color: ${({ theme }) => theme.colors.GREY_DARKER};
    margin-bottom: ${({ theme }) => theme.spacing.S8};
  }
`;

const A11yButton = styled.button`
  display: flex;
  cursor: pointer;
  ${({ theme }) => theme.fontSize.M16};
  padding: 0;
`;

const Label = styled.label`
  ${({ theme }) => theme.fontSize.M16};
  color: ${({ theme }) => theme.colors.GREY_DARKER};
  display: flex;
  gap: ${({ theme }) => theme.spacing.S4};
`;

const Count = styled.div`
  ${({ theme }) => theme.fontSize.M16};
  color: ${({ theme }) => theme.colors.GREY_DARK};
`;

export { Checkboxes, Count, Label, A11yButton };
