import { Checkbox } from 'components/Toolkit/Inputs/Checkbox';

import type { ListMultiSelectCheckboxProps } from 'features/filters/components/ListMultiSelectCheckbox/ListMultiSelectCheckbox.typed';
import * as Styled from 'features/filters/components/ListMultiSelectCheckbox/ListMultiSelectCheckbox.styled';

const ListMultiSelectCheckbox = (props: ListMultiSelectCheckboxProps) => {
  const { label, options, onSelectOption, selectedOptions } = props;

  return (
    <Styled.Checkboxes>
      <legend>{label}</legend>
      {options.map((option, index) => {
        const isSelected =
          selectedOptions &&
          typeof selectedOptions.find(
            (selectedOption) => selectedOption === option.value,
          ) === 'string';

        return (
          <Styled.A11yButton
            onClick={(e) => {
              e.preventDefault();
              onSelectOption && onSelectOption(option.value, !!isSelected);
            }}
            key={`option-${index}`}
          >
            <Checkbox
              id={option.value}
              name={option.value}
              value={option.value}
              checked={!!isSelected}
            />
            <Styled.Label htmlFor={option.value}>
              <div>{option.label} </div>
              {option.count && (
                <Styled.Count>{`(${option.count})`}</Styled.Count>
              )}
            </Styled.Label>
          </Styled.A11yButton>
        );
      })}
    </Styled.Checkboxes>
  );
};

export { ListMultiSelectCheckbox };
