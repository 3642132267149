import {
  MakeModelData,
  MakeModelValue,
} from 'features/filters/components/MakeModel/MakeModel.typed';
import { IGetSearchFilterMakeModel } from 'api/types/searchPageApiTypes';

const mapMakeModelToOptions = (makeModels?: MakeModelValue[]) =>
  makeModels
    ? makeModels.map((item) => ({
        value: item.value ?? '',
        displayName: item.displayName ?? '',
      }))
    : [];

const mapModelOptions = (makeModelData: MakeModelData, makeValue: string) => {
  const modelsByMake: IGetSearchFilterMakeModel | boolean | undefined =
    makeModelData.model.length > 0 &&
    makeModelData.model.find((modelItem) => modelItem.makeName === makeValue);

  return modelsByMake
    ? [
        { value: '', displayName: 'All Models' },
        { value: '', displayName: 'Popular' },
        ...mapMakeModelToOptions(modelsByMake.popular),
        { value: '', displayName: 'All Models' },
        ...mapMakeModelToOptions(modelsByMake.all),
      ]
    : [];
};

const mapMakeOptions = (makeModelData: MakeModelData) => {
  const makeAll = mapMakeModelToOptions(makeModelData.make.all);
  const makePopular = mapMakeModelToOptions(makeModelData.make.popular);
  const allMakeOptions =
    makePopular && makePopular.length > 0
      ? [
          { value: '', displayName: 'All Makes' },
          { value: '', displayName: 'Popular' },
          ...makePopular,
          { value: '', displayName: 'All Makes' },
          ...makeAll,
        ]
      : [{ value: '', displayName: 'All Makes' }, ...makeAll];

  return allMakeOptions;
};

export { mapMakeModelToOptions, mapMakeOptions, mapModelOptions };
