import type {
  ISearchPageFilterValue,
  TSearchPageFilterValueCategory,
} from 'api/types/searchPageApiTypes';

import { formatNumber } from 'helpers/formatting';

const getSingleOptionDisplay = (
  values: Array<ISearchPageFilterValue | TSearchPageFilterValueCategory>,
  target?: string,
) => {
  const matchingValue = values
    ? values.find(({ value }) => value === target)
    : undefined;
  return matchingValue?.displayName;
};

const getMultiOptionDisplay = (
  values: Array<ISearchPageFilterValue>,
  targets: Array<string>,
) => {
  const matchingDisplayValues = getMatchingValues(values, targets);

  return displayValueWithCount(matchingDisplayValues);
};

const getMatchingValues = (
  values: Array<ISearchPageFilterValue>,
  targets: Array<string>,
) => {
  const matchingDisplayValues = values.filter(({ value }) =>
    targets.some((target) => value === target),
  );

  return matchingDisplayValues;
};

const displayValueWithCount = (values: Array<ISearchPageFilterValue>) => {
  const [firstValue] = values;
  const multipleValuesText = firstValue
    ? `${firstValue.displayName} (+${values.length - 1})`
    : undefined;
  const singleValueText = firstValue ? firstValue.displayName : '';
  const formattedText = `${
    values.length > 1 ? multipleValuesText : singleValueText
  }`;

  return firstValue ? formattedText : undefined;
};

const getMultiOptionDisplayVariant = (
  values: Array<ISearchPageFilterValue>,
  targets: Array<string>,
  name: string,
) => {
  const matchingDisplayValues = getMatchingValues(values, targets);

  return displayValueWithCountVariant(matchingDisplayValues, name);
};

const displayValueWithCountVariant = (
  values: Array<ISearchPageFilterValue>,
  name: string,
) => {
  const [firstValue] = values;
  const multipleValuesText = `${values.length} ${name}s`;
  const singleValueText = firstValue ? firstValue.displayName : '';
  const formattedText = `${
    values.length > 1 ? multipleValuesText : singleValueText
  }`;

  return firstValue ? formattedText : undefined;
};

const getPriceDisplay = (args: {
  price_from?: string;
  price_to?: string;
  sterlingPrice_from?: string;
  sterlingPrice_to?: string;
}) => {
  const { price_from, price_to, sterlingPrice_from, sterlingPrice_to } = args;

  if (price_from || price_to) {
    const formattedFrom = formatPrice('€', price_from);
    const formattedTo = formatPrice('€', price_to);

    return getRangeDisplay({ from: formattedFrom, to: formattedTo });
  } else if (sterlingPrice_from || sterlingPrice_to) {
    const formattedFrom = formatPrice('£', sterlingPrice_from);
    const formattedTo = formatPrice('£', sterlingPrice_to);

    return getRangeDisplay({ from: formattedFrom, to: formattedTo });
  } else return undefined;
};

const formatPrice = (currency: '€' | '£', value?: string) => {
  return value ? `${currency}${formatNumber(parseInt(value))}` : undefined;
};

const getRangeDisplay = (args: { from?: string; to?: string }) => {
  const { from, to } = args;
  if (from && to) {
    if (from === to) {
      return from;
    } else return `${from} - ${to}`;
  } else if (from) {
    return `Min ${from}`;
  } else if (to) {
    return `Max ${to}`;
  } else return undefined;
};

const formatMakeAndModelQueries = (makeQuery?: string | Array<string>) => {
  if (typeof makeQuery === 'string') {
    const { make, models } = getMakeAndModelValues(makeQuery);
    return `${make}${formatModels(models)}`;
  } else if (Array.isArray(makeQuery)) {
    let modelAggregate: Array<string> = [];

    makeQuery.forEach((query) => {
      const { models } = getMakeAndModelValues(query);
      modelAggregate.push(...models);
    });

    return `${makeQuery.length} Makes${formatModels(modelAggregate)}`;
  } else return undefined;
};

const getMakeAndModelValues = (makesAndModelsQueryValue: string) => {
  const [make, modelsJoined] = makesAndModelsQueryValue.split(';model:');
  const modelsSplit = modelsJoined ? modelsJoined.split(',') : [];

  return { make, models: modelsSplit };
};

const formatModels = (models: Array<string>) => {
  const plural = models.length > 1 ? 's' : '';
  return models.length > 0 ? `, ${models.length} Model${plural}` : '';
};

export {
  getSingleOptionDisplay,
  getMultiOptionDisplay,
  getMultiOptionDisplayVariant,
  getPriceDisplay,
  getRangeDisplay,
  formatMakeAndModelQueries,
};
