import {
  getFiltersUrl,
  getUrlWithMakeModelEditable,
} from 'features/filters/Filters.helper';
import { useFiltersState } from 'features/filters/Filters.context';
import { useURLStateManagement } from 'components/SearchPage/hooks/useURLStateManagement/useURLStateManagement';
import { useLocationContext } from 'features/location/Location.context';
import { useMakeModelContext } from 'components/SearchPage/features/QuickFilters/components/MakeModel/MakeModel.context';
import { useNextRouter } from 'hooks/UseNextRouter';
import { getSectionParams } from 'helpers/seo/searchParams';
import { mapMakeModels } from 'features/filters/Filters.mapper';
import { IQueryParams } from 'types';
import { useSearchPageState } from 'components/SearchPage/context/SearchPageContext';
import { MakeModelConfigurationProps } from 'features/filters/FiltersV2.typed';

const useFilterBottomSheetMakeModel = () => {
  const { updateUrlByHref } = useNextRouter();
  const { selectedCounty, coordinates: initialCoordinates } =
    useLocationContext();
  const { makeModelState, setMakeModelState } = useMakeModelContext();
  const { filters, filtersData, ranges, staticParams } = useFiltersState();
  const {
    currentSectionQueryValue,
    countyTownQueryValue,
    radiusQueryValue,
    latitudeQueryValue,
    longitudeQueryValue,
  } = useURLStateManagement();
  const validRadius = countyTownQueryValue ? radiusQueryValue : undefined;
  const county = selectedCounty?.value ?? null;
  const { baseUrl } = useSearchPageState();

  const latitude = latitudeQueryValue || initialCoordinates.latitude;
  const longitude = longitudeQueryValue || initialCoordinates.longitude;

  const hiddenQueryValues =
    latitude && longitude ? `&latitude=${latitude}&longitude=${longitude}` : '';

  const mapMakeModelUrl = (urlString: string): IQueryParams => {
    const queryParams: IQueryParams = {};
    const url = new URL(urlString);

    const pathSegments = url.pathname
      .split('/')
      .filter((segment) => segment !== '')
      .map((item) => decodeURIComponent(item));
    if (pathSegments.length > 0) {
      queryParams['section'] = pathSegments;
    }

    url.searchParams.forEach((value, key) => {
      queryParams[key] =
        key === 'make' ? url.searchParams.getAll('make') : value;
    });

    return queryParams;
  };

  const makeModelCallback = (href: string) => {
    const query = mapMakeModelUrl(`${baseUrl}${href}`);
    const { makeModelParams } = getSectionParams(filtersData, query);
    const newFiltersMakeModel = mapMakeModels(makeModelParams, query);
    setMakeModelState(newFiltersMakeModel);
  };

  const makeModelConfiguration: MakeModelConfigurationProps = {
    onChange: makeModelCallback,
    value: makeModelState,
  };

  const handleSelectMakeModel = () => {
    const url = getFiltersUrl(
      currentSectionQueryValue,
      staticParams,
      filters,
      makeModelState,
      ranges,
      countyTownQueryValue,
      validRadius,
      county,
    );
    const editableMakeModel = makeModelState.find((item) => item.isEditable);

    if (editableMakeModel) {
      const updatedUrlAs = getUrlWithMakeModelEditable(
        url,
        editableMakeModel.id,
      );
      updateUrlByHref(`${updatedUrlAs}${hiddenQueryValues}`, url);
    } else {
      updateUrlByHref(`${url}${hiddenQueryValues}`);
    }
  };

  const clearMakeModel = () => {
    const clearedMakeModels = makeModelState.map((_item, index) => ({
      id: `make_model_${index}`,
      makeValue: '',
      modelValue: [],
      isEditable: index === 0,
      isVisible: false,
    }));
    setMakeModelState(clearedMakeModels);
  };

  return {
    handleSelectMakeModel,
    makeModelConfiguration,
    clearMakeModel,
  };
};

export { useFilterBottomSheetMakeModel };
