import { LinkButton } from 'components/Toolkit/Button/LinkButton';

import { useFiltersDispatch } from 'features/filters/Filters.context';

import {
  Header,
  Heading,
  StyledLastSearchCard,
} from 'features/filters/components/FiltersPanel/FiltersPanel.styled';

import type { FiltersPanelProps } from 'features/filters/components/FiltersPanel/FiltersPanel.typed';

const FiltersPanel = (props: FiltersPanelProps) => {
  const {
    children,
    lastSearchData,
    lastSearchCallback,
    showSearch = true,
    className,
  } = props;

  const { reset } = useFiltersDispatch();

  return (
    <div className={className}>
      <Header>
        <Heading>Filters</Heading>
        <LinkButton onClick={reset}>Reset All</LinkButton>
      </Header>
      {showSearch && (
        <StyledLastSearchCard
          lastSearchData={lastSearchData}
          lastSearchCallback={lastSearchCallback}
          isDisabled={!!!lastSearchData}
          lastSearchDataTrackingValue="last_search_search_page"
        />
      )}
      {children}
    </div>
  );
};

export { FiltersPanel };
