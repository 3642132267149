import { useState } from 'react';
import styled from 'styled-components';
import { Label } from 'components/Toolkit/Inputs/Label';
import { Inputs, TextField } from 'components/Toolkit/Inputs/TextField';
import { useOnUpdateOnly } from 'hooks/UseOnUpdateOnly';

export interface ITextInputRangeProps {
  label: string;
  value?: {
    from: string | undefined;
    to: string | undefined;
  };
  onChange: (from: string | undefined, to: string | undefined) => void;
}

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InputContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.spacing.S12};
  ${Inputs} {
    margin-bottom: ${({ theme }) => theme.spacing.S4};
  }
`;

function TextInputRange({ label, value, onChange }: ITextInputRangeProps) {
  const [from, setFrom] = useState<string | undefined>(value?.from);
  const [to, setTo] = useState<string | undefined>(value?.to);

  useOnUpdateOnly(() => {
    setFrom(value?.from);
    setTo(value?.to);
  }, [value?.from, value?.to]);

  return (
    <div>
      <HeaderContainer>
        <Label htmlFor={`search-input-filter-for-${label}`}>{label}</Label>
      </HeaderContainer>
      <InputContainer>
        <TextField
          id={`search-input-filter-for-${label}`}
          type="number"
          inputMode="numeric"
          value={from}
          placeholder="Min"
          pattern="\d*"
          onChange={(from) => {
            setFrom(from);
            onChange(from, to);
          }}
          autoComplete="off"
        />
        <TextField
          type="number"
          inputMode="numeric"
          value={to}
          placeholder="Max"
          pattern="\d*"
          onChange={(to) => {
            setTo(to);
            onChange(from, to);
          }}
          autoComplete="off"
        />
      </InputContainer>
    </div>
  );
}

export { TextInputRange };
