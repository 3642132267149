import { Button } from 'components/Toolkit/Button/Button';

import type { SavedSearchButtonProps } from 'components/SearchPage/components/SavedSearchButton/SavedSearchButton.typed';

import { SaveIcon } from 'components/SearchPage/components/SavedSearchButton/SavedSearchButton.styled';

const SavedSearchButton = (props: SavedSearchButtonProps) => {
  const { disabled, onClick, className } = props;

  return (
    <Button
      data-testid="save-search-button"
      disabled={disabled}
      icon={<SaveIcon disabled={disabled} height={20} width={20} />}
      onClick={onClick}
      ofType="PRIMARY"
      className={className}
    >
      Save Search
    </Button>
  );
};

export { SavedSearchButton };
