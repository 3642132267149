import { useEffect } from 'react';
import { DFPManager } from '@dsch/react-dfp';
import { IDFP } from 'api/types/searchPageApiTypes';
import {
  getTargetingArguments,
  refreshAdSlots,
} from 'components/Toolkit/DFP/DFP';
import { rg4js } from 'helpers/raygun';

function useDFP(settings: IDFP) {
  useEffect(() => {
    try {
      DFPManager.setTargetingArguments({
        ...getTargetingArguments(),
        ...settings,
      });
    } catch (e) {
      rg4js('send', {
        error: e,
        message: 'Error while trying to set arguments on DFP',
      });
    }
  }, []);

  function refresh() {
    DFPManager.setTargetingArguments({
      ...getTargetingArguments(),
      ...settings,
    });
    refreshAdSlots();
  }

  return {
    refresh,
  };
}

export { useDFP };
