import styled from 'styled-components';

import { Label } from 'components/Toolkit/Inputs/Label';
import { SimpleDropdown } from 'components/Toolkit/SimpleDropdown/SimpleDropdown';
import { Menu } from 'components/Toolkit/Menu/Menu';
import { removeNonNumericCharacters } from 'helpers/forms';

import { CURRENCIES } from 'types';
import { Select, StrictOption } from 'components/Toolkit/Inputs/Select';
import { mapOptionsRanges } from 'features/filters/Filters.mapper';

export type DropDownValue = {
  options: StrictOption[];
  selectedValue: string;
};

export interface IPriceSelectRangeProps {
  value?: {
    from: string | undefined;
    to: string | undefined;
    currency: CURRENCIES | undefined;
  };
  onChangePrice: (
    currency: CURRENCIES,
    from: string | undefined,
    to: string | undefined,
  ) => void;

  from: DropDownValue;
  to: DropDownValue;
  label?: string;
}

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InputContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;

  .divider {
    background: ${({ theme }) => theme.colors.GREY};
    width: 1px;
    height: 40px;
  }

  div:first-child {
    > select {
      border-right: 0;
      border-radius: ${({ theme }) =>
        `${theme.borderRadius.default} 0 0 ${theme.borderRadius.default}`};
    }
  }

  div:last-child {
    > select {
      border-left: 0;
      border-radius: ${({ theme }) =>
        `0 ${theme.borderRadius.default} ${theme.borderRadius.default} 0`};
    }    }
}
`;

type PriceCurrency = {
  value: CURRENCIES;
  displayName: string;
  symbol: string;
};

const euro: PriceCurrency = {
  value: CURRENCIES.EUR,
  displayName: '€ EUR',
  symbol: '€',
};

const pound: PriceCurrency = {
  value: CURRENCIES.GBP,
  displayName: '£ GBP',
  symbol: '£',
};

const PriceSelectRange = (props: IPriceSelectRangeProps) => {
  const { value, onChangePrice, from, to, label = 'Price' } = props;

  const currency = value?.currency ?? CURRENCIES.EUR;
  const fromValue = value?.from;
  const toValue = value?.to;

  const priceCurrency = currency === CURRENCIES.GBP ? pound : euro;

  const handleOnCurrencyChange = (currency: CURRENCIES) => {
    onChangePrice(currency, fromValue, toValue);
  };

  return (
    <div>
      <HeaderContainer>
        <Label htmlFor="search-price-filter-for">{label}</Label>
        <SimpleDropdown text={priceCurrency.displayName} variant="PRIMARY">
          <Menu
            items={[
              {
                displayName: euro.displayName,
                onClick: () => handleOnCurrencyChange(euro.value),
              },
              {
                displayName: pound.displayName,
                onClick: () => handleOnCurrencyChange(pound.value),
              },
            ]}
          />
        </SimpleDropdown>
      </HeaderContainer>
      <InputContainer>
        <Select
          onChange={(from) => {
            onChangePrice(
              priceCurrency.value,
              removeNonNumericCharacters(from),
              toValue,
            );
          }}
          name={'price_range_from'}
          value={from && from.selectedValue && from.selectedValue}
          options={from && from.options}
        />
        <span className="divider" />
        <Select
          onChange={(to) => {
            onChangePrice(
              priceCurrency.value,
              fromValue,
              removeNonNumericCharacters(to),
            );
          }}
          name={'price_range_to'}
          value={to && to.selectedValue && to.selectedValue}
          options={to && mapOptionsRanges(from, to)}
        />
      </InputContainer>
    </div>
  );
};

export { PriceSelectRange };
