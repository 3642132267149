import React, { useState } from 'react';
import { Button } from 'components/Toolkit/Button/Button';
import { Dropdown } from 'components/Toolkit/Dropdown/Dropdown';
import { InputButton } from 'components/Toolkit/InputButton/InputButton';
import { BottomSheet } from 'components/Toolkit/BottomSheet/BottomSheet';
import { Label } from 'components/Toolkit/Inputs/Label';
import { StickyFooter } from 'components/Toolkit/StickyFooter/StickyFooter';
import { Columns } from 'components/Toolkit/Columns/Columns';
import { Spacer } from 'components/DealerPage/styles';
import { useOnUpdateOnly } from 'hooks/UseOnUpdateOnly';
import * as Styled from 'features/filters/components/BottomSheetList/BottomSheetList.styled';
import type { BottomSheetListProps } from 'features/filters/components/BottomSheetList/BottomSheetList.typed';
import { useWindowSize } from 'hooks/UseWindowSize';
import { SCREEN_XLARGE } from 'helpers/breakpoints';

function BottomSheetList(props: BottomSheetListProps) {
  const {
    label,
    placeholder,
    onClear,
    onDone,
    isLoading,
    isDisabled,
    bottomSheetClassName,
    onClose,
    children,
    id,
  } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOnDone = () => {
    setIsOpen(false);
    onDone && onDone();
  };

  const handleOnClear = () => {
    onClear && onClear();
  };

  useOnUpdateOnly(() => {
    if (!isOpen) {
      onClose && onClose();
    }
  }, [isOpen]);

  const { width } = useWindowSize();
  const isDesktop = width && width >= SCREEN_XLARGE;
  const isMobile = !isDesktop;

  return (
    <Styled.ListWrapper>
      {label && <Label htmlFor={id}>{label}</Label>}
      {isMobile && (
        <Styled.Mobile isDisabled={isDisabled}>
          <InputButton
            isActive={isOpen}
            value={placeholder}
            onClick={() => !isLoading && setIsOpen(true)}
            placeholder={placeholder}
            isLoading={isLoading}
            isDisabled={isDisabled}
            id={id}
          />
          <BottomSheet
            headerClassName={bottomSheetClassName}
            buttonA={
              <Button ofType="SECONDARY" onClick={handleOnClear}>
                Clear
              </Button>
            }
            buttonB={
              <Button ofType="PRIMARY" onClick={handleOnDone}>
                Done
              </Button>
            }
            isOpen={isOpen}
            askToClose={() => setIsOpen(false)}
            headerText={placeholder}
            onApply={() => setIsOpen(false)}
          >
            <Styled.List>{children}</Styled.List>
          </BottomSheet>
        </Styled.Mobile>
      )}
      {isDesktop && (
        <Styled.Desktop isDisabled={isDisabled}>
          <Dropdown
            placeholder={placeholder}
            expanded={isOpen}
            setExpanded={(isExpanded: boolean) =>
              !isLoading && setIsOpen(isExpanded)
            }
            displayedValue={placeholder}
            isLoading={isLoading}
            isDisabled={isDisabled}
            buttonId={id}
          >
            <Styled.List>{children}</Styled.List>
            <StickyFooter position="sticky">
              <Columns>
                <Button ofType="SECONDARY" onClick={handleOnClear}>
                  Clear
                </Button>
                <Button onClick={handleOnDone}>Done</Button>
              </Columns>
            </StickyFooter>
          </Dropdown>
        </Styled.Desktop>
      )}
      <Spacer />
    </Styled.ListWrapper>
  );
}

export default Object.assign(BottomSheetList, {
  ListItem: Styled.ListItem,
  ListHeadingItem: Styled.ListHeadingItem,
});
