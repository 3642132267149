import styled, { css } from 'styled-components';

import { Label } from 'components/Toolkit/Inputs/Label';
import type { ListMultiSelectOption } from 'features/filters/components/ListMultiSelect/ListMultiSelect.typed';
import { Spacer } from 'features/filters/Filters.styled';

export interface IListMultiSelectTagProps {
  name: string;
  label?: string;
  options: ListMultiSelectOption[];
  selectedOptions?: Array<string>;
  onSelectOption: (option: string, isSelected?: boolean) => void;
  onClear?: () => void;
  onDone?: () => void;
}

const Tags = styled.ul`
  display: flex;
  flex-wrap: wrap;

  li {
    margin-right: ${({ theme }) => theme.spacing.S8};
    margin-top: ${({ theme }) => theme.spacing.S8};
  }
`;

// text prop is to create bold text that doesn't cause layout shift
export const Tag = styled.button<{ selected: boolean; text: string }>`
  position: relative;
  display: block;
  padding: ${({ theme }) => theme.spacing.S8};
  ${({ theme }) => theme.fontSize.M16};
  border: 1px solid ${({ theme }) => theme.colors.GREY};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  color: ${({ theme }) => theme.colors.GREY_DARK};
  background-color: ${({ theme }) => theme.colors.WHITE};
  cursor: pointer;
  white-space: nowrap;

  &:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
    font-weight: bold;
    content: ${({ text }) => `"${text}"`};
    color: ${({ theme }) => theme.colors.GREY_DARKER};
  }

  &:hover,
  &:focus,
  &:active {
    outline: none;
    color: ${({ theme }) => theme.colors.BLUE};
    border-color: ${({ theme }) => theme.colors.BLUE};
  }

  // Selected styles
  ${({ selected, theme }) =>
    selected &&
    css`
      box-shadow: inset 0 0 0 1px ${theme.colors.GREY_DARKER};
      border-color: ${theme.colors.GREY_DARKER};
      color: transparent;

      &:before {
        display: block !important;
      }

      &:hover,
      &:focus,
      &:active {
        box-shadow: inset 0 0 0 1px ${theme.colors.GREY_DARKER};
        border-color: ${theme.colors.GREY_DARKER};
        color: transparent;
      }
    `}
`;

function ListMultiSelectTag(props: IListMultiSelectTagProps) {
  const { label, options, onSelectOption, selectedOptions } = props;
  return (
    <div>
      {label && <Label>{label}</Label>}
      <Tags>
        {options.map((option, index) => {
          const isSelected =
            selectedOptions &&
            typeof selectedOptions.find(
              (selectedOption) => selectedOption === option.value,
            ) === 'string';

          return (
            <li key={index}>
              <Tag
                selected={!!isSelected}
                text={option.label}
                onClick={() =>
                  onSelectOption && onSelectOption(option.value, !!isSelected)
                }
              >
                <span>{option.label}</span>
              </Tag>
            </li>
          );
        })}
      </Tags>
      <Spacer />
    </div>
  );
}

export { ListMultiSelectTag };
