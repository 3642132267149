import { useRef, useState } from 'react';
import styled from 'styled-components';

import {
  BaseSearch,
  IHandleSelectSearchItem,
} from 'components/Toolkit/SearchModal/BaseSearch';
import { autoCompleteApi } from 'api/autoCompleteApi';
import { matchMedia, media } from 'helpers/breakpoints';
import { disableScroll, enableScroll } from 'helpers/scroll';
import { useURLStateManagement } from 'components/SearchPage/hooks/useURLStateManagement/useURLStateManagement';

import type { IAutoCompleteSuggestion } from 'types';
import { rg4js } from 'helpers/raygun';
import { PAGE } from 'helpers/pages';

export interface KeywordSearchBoxProps {
  section: string;
  sectionName: string;
  placeholder: string;
  onClickSuggestion: ({
    autoSuggestedText,
    searchRequest,
  }: IHandleSelectSearchItem) => void;
}

const Container = styled.div`
  position: relative;

  // Invisible spacer when scrolling to the top of search
  &:before {
    display: block;
    margin-top: -${({ theme }) => theme.spacing.S8};
    padding-top: ${({ theme }) => theme.spacing.S8};
    content: '';
  }
`;

function KeywordSearchBox(props: KeywordSearchBoxProps) {
  const { placeholder, onClickSuggestion, section, sectionName } = props;
  const searchRef = useRef<HTMLDivElement | null>(null);
  const [autoSuggestItems, setAutoSuggestItems] = useState<
    IAutoCompleteSuggestion[] | undefined
  >();
  const { keywordQueryValue, updateURL } = useURLStateManagement();

  // TODO: Update when we can change sections in new UI
  async function handleSearchInput(text: string) {
    const isAllSection = section?.toLowerCase() === 'all';
    const rightTextMuted = `In ${sectionName}`;
    if (text.length > 2) {
      try {
        const { data } = await autoCompleteApi.getAutoCompleteValues(
          text,
          section,
        );
        const filteredData: IAutoCompleteSuggestion[] = [
          ...data.suggestions.map((suggestion) => {
            return {
              ...suggestion,
              rightTextMuted,
            };
          }),
        ];

        // We need to manually add search term to start if not suggested
        if (filteredData[0]?.leftText !== text) {
          filteredData.unshift({
            url: '',
            leftText: text,
            rightText: '',
            rightTextMuted,
            searchRequest: {
              terms: text,
              sections: [section],
            },
          });
        }

        /* We need to manually add global search for term to end of array
        if not in 'all' sections */
        if (!isAllSection) {
          filteredData.push({
            url: `/all?words=${text}`,
            rightText: 'In All Sections',
            leftText: text,
            searchRequest: {
              terms: text,
              sections: ['all'],
            },
          });
        }

        setAutoSuggestItems(filteredData);
      } catch (error) {
        rg4js('send', {
          error: new Error('Error handling handleSearchInput'),
          tags: [PAGE.SEARCH],
          customData: {
            text: text,
            keywordQueryValue: keywordQueryValue,
            autoSuggestItems: autoSuggestItems,
            section: section,
            props: props,
            message: error.message || 'client_error',
          },
        });
      }
    } else {
      setAutoSuggestItems([]);
    }
  }

  function handleScroll() {
    // Slight hack to not immediately prevent scroll until animation is complete
    if (window.scrollY === searchRef.current?.offsetTop) {
      disableScroll();
    }
  }

  function handleFocus() {
    if (matchMedia(media.smallOnly)) {
      window.scroll({
        top: searchRef.current?.offsetTop,
        behavior: 'smooth',
      });
      window.addEventListener('scroll', handleScroll);
    }
  }

  function handleBlur() {
    enableScroll();
    window.removeEventListener('scroll', handleScroll);
  }

  const onSearch = (value: string) => {
    updateURL({
      queryParams: {
        words: value,
      },
    });
  };

  return (
    <Container ref={searchRef}>
      <BaseSearch
        placeholder={placeholder}
        onHandleSearch={onSearch}
        cdnUrl=""
        variant="INLINE"
        onInput={handleSearchInput}
        onHandleSelectSearchItem={onClickSuggestion}
        onClose={() => setAutoSuggestItems(undefined)}
        onFocus={handleFocus}
        onBlur={handleBlur}
        closeOnSearch
        allowEmptySearch
        presetInput={keywordQueryValue}
        autoSuggestItems={autoSuggestItems}
      />
    </Container>
  );
}

export { KeywordSearchBox };
