/**
 * This function will handle mapping query parameters which can be an array to a string
 * @param value the query parameter
 * @returns A string value
 */
export const asString = (
  value: string | string[] | undefined,
): string | undefined => {
  if (Array.isArray(value)) return value[0];
  if (value) return value;
  return undefined;
};

export const asFrom = (keyName: string) => {
  return `${keyName}_from`;
};

export const asTo = (keyName: string) => {
  return `${keyName}_to`;
};
