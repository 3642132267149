import { BottomSheet } from 'components/Toolkit/BottomSheet/BottomSheet';
import { Button } from 'components/Toolkit/Button';
import { FiltersV2 } from 'features/filters/FiltersV2';
import { useFilterBottomSheet } from 'components/SearchPage/features/QuickFilters/components/FilterBottomSheet/FilterBottomSheet.hook';
import { useQuickFilterContext } from 'components/SearchPage/features/QuickFilters/QuickFilters.context';
import * as Styled from 'components/SearchPage/features/QuickFilters/components/FilterBottomSheet/FilterBottomSheet.styled';

const FilterDisplay = () => {
  const {
    currentFilter,
    isFilterBottomSheetOpen: isOpen,
    onCloseFilterDisplay: askToClose,
  } = useQuickFilterContext();

  const {
    onClickDone,
    onClickClear,
    onChange,
    value,
    locationConfiguration,
    makeModelConfiguration,
    appliedFilterID,
  } = useFilterBottomSheet({
    askToClose,
  });

  return (
    <BottomSheet
      headerText={currentFilter?.displayName ?? ''}
      askToClose={askToClose}
      isOpen={isOpen}
      height=""
      variant="DIALOG"
    >
      {currentFilter && (
        <FiltersV2
          filter={{
            ...currentFilter,
            displayName: '',
            onChange,
            value,
          }}
          locationConfiguration={locationConfiguration}
          makeModelConfiguration={makeModelConfiguration}
        />
      )}
      <Styled.ButtonContainer>
        <Button ofType="SECONDARY" onClick={onClickClear}>
          Clear
        </Button>
        <Button
          onClick={onClickDone}
          data-tracking={appliedFilterID}
          data-testid={'quick-filter-done'}
        >
          Done
        </Button>
      </Styled.ButtonContainer>
    </BottomSheet>
  );
};

export { FilterDisplay };
