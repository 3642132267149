import type { ReactNode } from 'react';
import React from 'react';
import styled from 'styled-components';
import { ISeoFaqSchema } from 'api/types/searchPageApiTypes';
import { media } from 'helpers/breakpoints';
import { capitalizeFirstLetter } from 'helpers/string';

export interface INoResults {
  headerText: string;
  subText: string | ReactNode;
  imageSrc?: string;
  className?: string;
}

interface FAQItemProps {
  question: string;
  answer: ReactNode;
}

export interface IFaq extends ISeoFaqSchema {
  makeModel: string | null;
}

const SFaq = styled.div`
  background-color: ${({ theme }) => `${theme.colors.WHITE}`};
  padding: ${({ theme }) => `${theme.spacing.L40} ${theme.spacing.M16}`};
`;

const SFaqTitle = styled.h2`
  ${({ theme }) => theme.fontSize.B18};
  color: ${({ theme }) => `${theme.colors.GREY_DARKER}`};
  margin-bottom: ${({ theme }) => theme.spacing.M16};
`;
const SFaqContainer = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: ${({ theme }) => theme.spacing.M16};

  ${media.large} {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: ${({ theme }) => theme.spacing.M16};
    grid-row-gap: ${({ theme }) => theme.spacing.M24};
  }
`;

const SFaqItemQuestion = styled.p`
  ${({ theme }) => theme.fontSize.M16};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.GREY_DARKER};
  margin-bottom: ${({ theme }) => theme.spacing.S8};
`;

const SFaqItemAnswer = styled.div`
  ${({ theme }) => theme.fontSize.M16};
  color: ${({ theme }) => `${theme.colors.GREY_DARK}`};
`;

const FaqItemContainer = styled.div`
  border-left: ${({ theme }) => `1px solid ${theme.colors.GREY_LIGHT}`};
  padding-left: ${({ theme }) => theme.spacing.M16};
`;

const FaqItem = ({ question, answer }: FAQItemProps) => {
  return (
    <FaqItemContainer
      itemScope
      itemProp="mainEntity"
      itemType="https://schema.org/Question"
    >
      <SFaqItemQuestion itemProp="name">{question}</SFaqItemQuestion>
      <SFaqItemAnswer
        itemScope
        itemProp="acceptedAnswer"
        itemType="https://schema.org/Answer"
      >
        <div itemProp="text">{answer}</div>
      </SFaqItemAnswer>
    </FaqItemContainer>
  );
};

function Faq({
  avgPrice,
  avgMileage,
  topYear,
  topFuelType,
  topColor,
  makeModel,
}: IFaq) {
  const VEHICLE_TERM = makeModel ?? 'car';

  return (
    <SFaq>
      <div className="row small-collapse medium-uncollapse">
        <div className="small-12">
          <header>
            <SFaqTitle>About Cars in Ireland on DoneDeal</SFaqTitle>
          </header>
          <SFaqContainer itemScope itemType="https://schema.org/FAQPage">
            <FaqItem
              question={`What's the average price of a ${VEHICLE_TERM}?`}
              answer={
                <>
                  The average price of a {VEHICLE_TERM} in Ireland is&nbsp;
                  <b>{avgPrice}</b>
                </>
              }
            />
            <FaqItem
              question={`What's the average mileage of a ${VEHICLE_TERM}?`}
              answer={
                <>
                  The average mileage of a {VEHICLE_TERM} in Ireland is&nbsp;
                  <b>{capitalizeFirstLetter(avgMileage)}</b>
                </>
              }
            />
            <FaqItem
              question={`What's the most popular ${VEHICLE_TERM} year?`}
              answer={
                <>
                  The most popular {VEHICLE_TERM} year in Ireland is&nbsp;
                  <b>{topYear}</b>
                </>
              }
            />
            <FaqItem
              question={`What's the most popular ${makeModel ?? ''} fuel type?`}
              answer={
                <>
                  The most popular {makeModel ?? ''} fuel type in Ireland
                  is&nbsp;
                  <b>{capitalizeFirstLetter(topFuelType)}</b>
                </>
              }
            />
            <FaqItem
              question={`What's the most popular ${VEHICLE_TERM} colour?`}
              answer={
                <>
                  The most popular {VEHICLE_TERM} colour in Ireland is&nbsp;
                  <b>{capitalizeFirstLetter(topColor)}</b>
                </>
              }
            />
          </SFaqContainer>
        </div>
      </div>
    </SFaq>
  );
}

export { Faq };
