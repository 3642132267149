import { useRef } from 'react';

import { useQuickFilterContext } from 'components/SearchPage/features/QuickFilters/QuickFilters.context';
import { useFilterProps } from 'components/SearchPage/features/QuickFilters/FilterProps.hook';
import { useLocationReadURL } from 'features/location/hooks/LocationReadURL/LocationReadURL.hook';
import { useURLData } from 'components/SearchPage/features/QuickFilters/URLData.hook';

import { camelToSnakeCase } from 'helpers/formatting';

import type { UseQuickFilterProps } from 'components/SearchPage/features/QuickFilters/components/QuickFilter/QuickFilter.typed';

const useQuickFilter = (args: UseQuickFilterProps) => {
  const { filter } = args;

  const ref = useRef<HTMLButtonElement>(null);

  const { selectQuickFilter, onChangeFilterValue } = useQuickFilterContext();
  const { getFilterProps } = useFilterProps();
  const {
    makeQueryValue,
    currentFilterPathParameterValue,
    formattedFilterName,
    countyQueryValue,
    countyTownQueryValue,
    radiusQueryValue,
  } = useURLData(filter);

  const { value: initialValue, queryValue: initialQueryValue } = getFilterProps(
    filter?.filterType.name ?? '',
    filter?.name ?? '',
  );

  const makeValue =
    makeQueryValue && filter?.name === 'make' ? makeQueryValue : undefined;

  const countyPathParam =
    currentFilterPathParameterValue !== ''
      ? currentFilterPathParameterValue
      : undefined;

  const { handleCountyQueryValueChange, handleCountyTownQueryValueChange } =
    useLocationReadURL({
      countyQueryValue,
      countyPathParam,
      countyTownQueryValue,
    });

  const initialiseLocationFilter = () => {
    if (countyTownQueryValue) {
      handleCountyTownQueryValueChange(radiusQueryValue);
    } else {
      handleCountyQueryValueChange();
    }
  };

  const initialiseSEOFilter = (SEOFilterValue: string) => {
    const filterValue =
      filter.searchQueryGroup === 'ranges'
        ? [SEOFilterValue, SEOFilterValue]
        : SEOFilterValue;

    onChangeFilterValue({
      queryValue: {
        [filter.name]: SEOFilterValue,
      },
      filterValue,
    });
  };

  const initialiseSelectedFilterQueryValue = () => {
    if (filter?.name === 'location') {
      initialiseLocationFilter();
    } else if (
      currentFilterPathParameterValue &&
      filter?.name === formattedFilterName
    ) {
      initialiseSEOFilter(currentFilterPathParameterValue);
    } else if (makeValue) {
      onChangeFilterValue({
        queryValue: {
          [filter.name]: makeValue,
        },
        filterValue: makeValue,
      });
    } else {
      onChangeFilterValue({
        queryValue: initialQueryValue ?? {},
        filterValue: initialValue,
      });
    }
  };

  const scrollFilterToCentre = () => {
    const { current } = ref;

    if (current) {
      current.scrollIntoView({
        behavior: 'smooth',
        inline: 'center',
        block: 'nearest',
      });
    }
  };

  const onClick = () => {
    scrollFilterToCentre();
    selectQuickFilter(filter);
    initialiseSelectedFilterQueryValue();
  };

  const quickFilterID = `quick_filter_${camelToSnakeCase(filter.name)}`;

  return {
    ref,
    onClick,
    quickFilterID,
  };
};
export { useQuickFilter };
